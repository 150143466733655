import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {function} props.setShowRecurrenceModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const EditOrderRecurrenceButton = props => {
	return props.order.buttons.editRecurrence ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"editRecurrence"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.EDIT_RECURRENCE.X)}} left={{icon: ICONS.PENCIL}}
				padding={"1vh"} style={{width: "100%"}} disabled={false}	className={"paralian-theme-level-1"}
				onClick={() => {props.setShowRecurrenceModal(true)}}/>
		</div> : <></>
}

export default EditOrderRecurrenceButton