import React from "react"
import Dictionary from "../shared/dictionary"
import Icons from "./icons"
import {Pages, PageStates} from "./pages"

export const DICTIONARY = Dictionary

export const ICONS = Icons

export const PAGES = Pages

export const PAGE_STATES = PageStates

export const FIREBASE_CONFIG = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID
}

export const INPUT_ELEMENT_PARAMETERS = Object.freeze({
	FULL_NAME: {
		PATTERN: "([a-zA-ZÀ-ž0-9'&\. -]){2,45}",
		TITLE: "2-64 characters long. letters, numbers, periods, hyphens, apostrophes, ampersands, spaces only",
		LENGTH: {
			MIN: 2,
			MAX: 45
		}
	},
	MMSI: {
		PATTERN: "([0-9]){9,9}",
		TITLE: "9 digit number",
		LENGTH: {
			MIN: 9,
			MAX: 9
		},
		PLACEHOLDER: "123456789"
	},
	IMO: {
		PATTERN: "([0-9]){7,7}",
		TITLE: "7 digit number",
		LENGTH: {
			MIN: 7,
			MAX: 7
		},
		PLACEHOLDER: "1234567"
	},
	VESSEL_DOCUMENTATION: {
		PATTERN: "([0-9]){6,7}",
		TITLE: "6-7 digit number",
		LENGTH: {
			MIN: 6,
			MAX: 7
		},
		PLACEHOLDER: "1234567"
	},
	CALL_SIGN: {
		PATTERN: "([A-Z0-9]){3,7}",
		TITLE: "3-7 characters long. numbers and upper case letters only",
		LENGTH: {
			MIN: 3,
			MAX: 7
		},
		PLACEHOLDER: "AB12CD3"
	},
	HIN: {
		PATTERN: "([a-zA-Z0-9]){12,12}",
		TITLE: "12 characters long. numbers and letters only",
		LENGTH: {
			MIN: 12,
			MAX: 12
		},
		PLACEHOLDER: "ABC12345B101"
	},
	VESSEL_REGISTRATION: {
		PATTERN: "([A-Z0-9]){8,8}",
		TITLE: "8 characters long. numbers and upper case letters only",
		LENGTH: {
			MIN: 8,
			MAX: 8
		},
		PLACEHOLDER: "AA1234BB"
	}
})

export const DEVICE_TYPE = Object.freeze({DESKTOP: "DESKTOP", MOBILE: "MOBILE"})

export const FETCHER_OPTIONS = {
	JSON: {
		ROOT: process.env.REACT_APP_API_PATH,
		INIT: {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json'
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
		}
	},
	JSON_GET: {
		ROOT: process.env.REACT_APP_API_PATH,
		INIT: {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
		}
	},
	FILE_GET: {
		ROOT: "",
		INIT: {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', //'same-origin', // include, *same-origin, omit
			// headers: {
			// 	'Content-Type': 'multipart/form-data'
			// },
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
		}
	},
	FILE_UPLOAD: {
		ROOT: process.env.REACT_APP_API_PATH,
		INIT: {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			// headers: {
			// 	'Content-Type': 'multipart/form-data'
			// },
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
		}
	}
}

export const STYLE = {
	fields: {
		color: "paralian-theme-level-1-flipped"
	}
}

export const MONTH = [DICTIONARY.JANUARY.X, DICTIONARY.FEBRUARY.X, DICTIONARY.MARCH.X, DICTIONARY.APRIL.X, DICTIONARY.MAY.X,
	DICTIONARY.JUNE.X, DICTIONARY.JULY.X, DICTIONARY.AUGUST.X, DICTIONARY.SEPTEMBER.X, DICTIONARY.OCTOBER.X,
	DICTIONARY.NOVEMBER.X, DICTIONARY.DECEMBER.X]

export const TEXT_FIELD_PROPERTIES = Object.freeze({
	username: {
		inputType: "text",
		placeHolder: DICTIONARY.USERNAME.X,
		pattern: "([a-zA-ZÀ-ž0-9-_\.]){2,32}",
		maxLength: "32",
		size: "20",
		hoverTitle: DICTIONARY.USERNAME.X,
		hoverMessage: DICTIONARY.USERNAME_INFO_MESSAGE.X
	},
	phone: {
		inputType: "tel",
		placeHolder: DICTIONARY.PHONE_NUMBER.X,
		pattern: "([+()-\. 0-9]){8,22}",
		maxLength: "22",
		size: "20",
		hoverTitle: DICTIONARY.PHONE_NUMBER.X,
		hoverMessage: DICTIONARY.LOGIN_PHONE_INFO_MESSAGE.X
	},
	eMail: {
		inputType: "email",
		placeHolder: DICTIONARY.EMAIL.X,
		pattern: "([a-zA-Z0-9-!#$%&'*+\/=?^_`{|}~@\.]){6,120}",
		maxLength: "120",
		size: "20",
		hoverTitle: DICTIONARY.EMAIL.X,
		hoverMessage: DICTIONARY.LOGIN_EMAIL_INFO_MESSAGE.X
	},
	password: {
		inputType: "password",
		placeHolder: DICTIONARY.PASSWORD.X,
		pattern: "([a-zA-ZÀ-ž0-9-!#$%&'*+\/=?^_`{|}~@\.]){8,32}",
		maxLength: "32",
		size: "20",
		hoverTitle: DICTIONARY.PASSWORD.X,
		hoverMessage: DICTIONARY.LOGIN_PASSWORD_INFO_MESSAGE.X
	},
	website: {
		inputType: "website",
		placeHolder: DICTIONARY.WEBSITE.X,
		pattern: "([a-zA-Z0-9-_\.:/ ]){4,128}",
		maxLength: "128",
		size: "20",
		hoverTitle: DICTIONARY.WEBSITE.X,
		hoverMessage: DICTIONARY.WEBSITE.X
	},
	personalSecurityPhrase: {
		inputType: "psp",
		placeHolder: DICTIONARY.PERSONAL_SECURITY_PHRASE.X,
		pattern: "([a-zA-ZÀ-ž0-9-_\.?! ]){2,128}",
		maxLength: "128",
		size: "20",
		hoverTitle: DICTIONARY.PERSONAL_SECURITY_PHRASE.X,
		hoverMessage: DICTIONARY.PERSONAL_SECURITY_PHRASE.X
	},
	name: {
		inputType: "name",
		placeHolder: DICTIONARY.FULL_NAME.X,
		pattern: "([a-zA-ZÀ-ž- ]){2,128}",
		maxLength: "128",
		size: "20",
		hoverTitle: DICTIONARY.FULL_NAME.X,
		hoverMessage: DICTIONARY.FULL_NAME_MESSAGE.X
	},
	itemId: {
		inputType: "number",
		placeHolder: "ID",
		pattern: "([0-9]){1,45}",
		maxLength: "45",
		size: "20",
		hoverTitle: "ID",
		hoverMessage: "ID"
	}
})

export const LOGIN_TYPES = [
	["person", "username", "username", ICONS.USER, "username"],
	["phone", "loginPhone", "tel", ICONS.PHONE, "phone"],
	["email", "loginEmail", "email", ICONS.ENVELOPE, "eMail"]
]

export const MEASUREMENT_UNITS = {
	distance: ["mm", "cm", "m", "km", "nm", "in", "yd", "ft-us", "ft", "mi"],
	mass:   ["mcg", "mg", "g", "kg", "mt", "oz", "lb", "t"],
	volume: ["mm3", "cm3", "ml", "cl", "dl", "l", "kl", "m3", "km3", "krm", "tsk", "msk", "kkp", "glas", "kanna",
		"tsp", "Tbs", "in3", "fl-oz", "cup", "pnt", "qt", "gal", "ft3", "yd3"],
	speed:  ["m/s", "km/h", "knot", "m/h", "ft/s"]
}

export const IMAGES = {
	CAPTAIN:`${process.env.REACT_APP_IMAGES_PATH}/beard_logo_light_circle.png`,
	USER: `${process.env.REACT_APP_IMAGES_PATH}/user.svg`,
	EMPLOYEE: `${process.env.REACT_APP_IMAGES_PATH}/suit.svg`,
	SUPPLIER: `${process.env.REACT_APP_IMAGES_PATH}/hardhat.svg`,
	CONTRACTOR: `${process.env.REACT_APP_IMAGES_PATH}/welder.svg`,
	FLAG_UK:`${process.env.REACT_APP_IMAGES_PATH}/united-kingdom-flag-round-icon-64.png`,
	FLAG_SPAIN:`${process.env.REACT_APP_IMAGES_PATH}/spain-flag-round-icon-64.png`,
	FLAG_PORTUGAL:`${process.env.REACT_APP_IMAGES_PATH}/portugal-flag-round-icon-64.png`,
	FLAG_FRANCE: `${process.env.REACT_APP_IMAGES_PATH}/france-flag-round-icon-64.png`,
	LOGO_PARALIAN: `${process.env.REACT_APP_IMAGES_PATH}/bow_logo_light_horizontal.png`,
	// LOGO_HORIZONTAL: `${process.env.REACT_APP_IMAGES_PATH}/horizontal-logo.png`,
	LOGO_HORIZONTAL: `${process.env.REACT_APP_IMAGES_PATH}/bow_logo_dark_horizontal.png`,
	// LOGO_MOBILE: `${process.env.REACT_APP_IMAGES_PATH}/mobile-header-logo.png`,
	LOGO_MOBILE: `${process.env.REACT_APP_IMAGES_PATH}/bow-logo-dark-gold-circle-outline.png`,
	// LOGO_MOBILE: `${process.env.REACT_APP_IMAGES_PATH}/bow_logo_light_horizontal.png`,
	BACKGROUND_DESKTOP: `${process.env.REACT_APP_IMAGES_PATH}/beautiful-boat-daylight-273886.jpg`,
	HEIGHT: `${process.env.REACT_APP_IMAGES_PATH}/height.png`,
	HULL_HEIGHT: `${process.env.REACT_APP_IMAGES_PATH}/hull_height.png`,
	CLEARANCE: `${process.env.REACT_APP_IMAGES_PATH}/clearance.png`,
	HULL_CLEARANCE: `${process.env.REACT_APP_IMAGES_PATH}/hull_clearance.png`,
	BEAM: `${process.env.REACT_APP_IMAGES_PATH}/beam.png`,
	DRAUGHT: `${process.env.REACT_APP_IMAGES_PATH}/draft.png`,
	LOA: `${process.env.REACT_APP_IMAGES_PATH}/loa.png`,
	HULL_LOA: `${process.env.REACT_APP_IMAGES_PATH}/hull_loa.png`,
	RIG: `${process.env.REACT_APP_IMAGES_PATH}/rig.png`,
	LOADER: `${process.env.REACT_APP_IMAGES_PATH}/splash-loader.gif`,
	MOORINGS: `${process.env.REACT_APP_IMAGES_PATH}/boat-moorings.jpg`,
	SLIPS: `${process.env.REACT_APP_IMAGES_PATH}/boat-slip.jpg`,
	TRAILERS: `${process.env.REACT_APP_IMAGES_PATH}/boat-trailers.jpg`,
	RACKS: `${process.env.REACT_APP_IMAGES_PATH}/boat-racks.jpg`,
	CRADLES: `${process.env.REACT_APP_IMAGES_PATH}/boat-cradle.jpg`,
	BOATYARD: `${process.env.REACT_APP_IMAGES_PATH}/boat-yard.jpg`
}

export const TIME_NAMES = {
	month: ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"],
	day: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
}

export const AUTHENTICATION_STATES = {
	ERROR_SEARCHING_FOR_USER: "ERROR_SEARCHING_FOR_USER",
	USER_FOUND_NEW: "USER_FOUND_NEW",
	USER_FOUND_ACTIVE: "USER_FOUND_ACTIVE",
	USER_FOUND_CLOSED: "USER_FOUND_CLOSED",
	USER_FOUND_LOCKED: "USER_FOUND_LOCKED",
	USER_FOUND_DISABLED: "USER_FOUND_DISABLED",
	USER_FOUND_DEACTIVATED: "ACCOUNT_DEACTIVATED",
	USER_NOT_FOUND: "USER_NOT_FOUND",
	ERROR_VERIFYING_PASSWORD: "ERROR_VERIFYING_PASSWORD",
	PASSWORD_VERIFIED: "PASSWORD_VERIFIED",
	FIRST_LOGIN_ATTEMPT: "FIRST_LOGIN_ATTEMPT",
	LOGIN_SUCCESSFUL: "LOGIN_SUCCESSFUL",
	INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
	ROLE_SELECTED: "ROLE_SELECTED",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	JWT_INVALID: "JWT_INVALID",
	TOO_MANY_LOGIN_ATTEMPTS: "TOO_MANY_LOGIN_ATTEMPTS",
	NO_LINKS_BETWEEN_PERSONAL_ACCOUNTS: "NO_LINKS_BETWEEN_PERSONAL_ACCOUNTS"
}

export const LANGUAGE = [
	{
		name: "English",
		language: "Language",
		icon: <img style={{maxWidth: "18px"}} src={IMAGES.FLAG_UK} alt={"UK Flag"}/>,
		code: "en",
		menuShow: true,
		value: "en"
	},
	{
		name: "Español",
		language: "Idioma",
		icon: <img style={{maxWidth: "18px"}} src={IMAGES.FLAG_SPAIN} alt={"SPA Flag"}/>,
		code: "es",
		menuShow: true,
		value: "es"
	},
	/*
	{
		name: "Française",
		language: "Langue",
		icon: <img style={{maxWidth: "18px"}} src={IMAGES.FLAG_FRANCE} alt={"FR Flag"}/>,
		code: "fr",
		menuShow: true,
		value: "fr"
	},
	*/
	/*{
			name: "Português",
			language: "Língua",
			icon: <img style={{maxWidth: "18px"}} src={IMAGE_PATHS.FLAG_PORTUGAL} alt={"POR Flag"}/>,
			code: "pt",
			menuShow: true,
			value: "pt"
	}*/
]

export const UNITS = [
	{
		name: DICTIONARY.SI_METRIC.X,
		value: "SI"
	},
	{
		name: DICTIONARY.US_CUSTOMARY.X,
		value: "US"
	}
]

export const TIME = [
	{
		name: DICTIONARY.MILITARY_TIME.X,
		value: "24Hr"
	},
	{
		name: "AM/PM",
		value: "xm"
	}
]

export const TOOLS_DATA = {
	WIND_FRAME_DATA: {
		path: {
			prefix: "https://graphical.weather.gov/images/puertorico/WindSpd",
			suffix: "_puertorico.png"
		},
		frames: {
			min: 1,
			max: 20
		}},
	WAVE_FRAME_DATA: {
		path: {
			prefix: "https://graphical.weather.gov/images/puertorico/WaveHeight",
			suffix: "_puertorico.png"
		},
		frames: {
			min: 1,
			max: 20
		}},
}