import {actions} from "../../constants/client/reduxData"
import {store} from "../../../index"
import {DICTIONARY, FETCHER_OPTIONS, PAGE_STATES} from "../../constants/client/constants"
import {
	CONTACT_INFO_TYPE,
	IMAGE_CATEGORY,
	LOGIN_TYPE,
	ORDER_BUTTON,
	SERVER_RESPONSE_STATE
} from "../../constants/shared/enumerators"
import ICONS from "../../constants/client/icons"
import {connectionCheck, fadingMessage,	getFromStorage,	getLanguage, hideLoader, newMessage, showLoader, translate} from "./localFunctions"
import {compareObjects} from "../shared/local"
import getDeviceData from "./getDeviceData"
import setLoggedIn from "./mode/setLoggedIn"
import getLoggedIn from "./mode/getLoggedIn"
import ServerResponse from "../../classes/shared/ServerResponse"
import Coordinates from "../../classes/shared/Coordinates"

/* TODO: move all dispatch functions to localFunctions */

const _getFromCache = true, _saveToCache = true // , _deleteCache = false

const deleteCache = async key => {
	const cache = await caches.open(key)
	const keys = await cache.keys()
	for (let i = 0; i < keys.length; i++) {
		await cache.delete(keys[i], {ignoreMethod: true, ignoreSearch: true, ignoreVary: true})
	}
}

export const connectionErrorMessage = () => {
	fadingMessage("connectionError", translate(DICTIONARY.CONNECTION_ERROR.X),
		translate(DICTIONARY.CONNECTION_ERROR_MESSAGE.X), "w3-pale-red", ICONS.BAN)
}

const handleFetchResponse = (response, showMessage = true) => {
	switch (response.status) {
		case 418:
			console.log("error: 418 - logout")
			hideLoader()
			newMessage("login-timeout", translate(DICTIONARY.LOGIN_TIMEOUT.X), "",
				"w3-pale-yellow", ICONS.INFO_CIRCLE)
			setTimeout(() => store.dispatch({type: actions.SET_CURRENT_WINDOW,
				values: {window: PAGE_STATES.MOBILE.LOGIN.START.X}}), 3000)
			logOut()
			return
		case 412:
			console.log("error: 412 - jwt not found")
			hideLoader()
			newMessage("login-timeout", translate(DICTIONARY.LOGIN_TIMEOUT.X), "",
				"w3-pale-yellow", ICONS.INFO_CIRCLE)
			setTimeout(() => store.dispatch({type: actions.SET_CURRENT_WINDOW,
				values: {window: PAGE_STATES.MOBILE.LOGIN.START.X}}), 3000)
			logOut()
			return
		case 409:
			console.log("error: 409 - handled error")
			break
		case 408:
			console.log("error: 408 - request timeout")
			break
		case 406:
			console.log("error: 406 - not acceptable")
			if (showMessage) {
				newMessage("requestIssue", translate(DICTIONARY.ERROR.X), translate(DICTIONARY.ERROR_406_MESSAGE.X))
			}
			break
		case 404:
			console.log("error: 404 - path not found")
			break
		case 403:
			/* TODO: check that 406 & 403 error codes are being used correctly in server */
			console.log("error: 403 - forbidden")
			if (showMessage) {
				newMessage("forbidden", translate(DICTIONARY.FORBIDDEN.X), translate(DICTIONARY.ERROR_403_MESSAGE.X))
			}
			break
		case 401:
			console.log("error: 401 - unauthorized")
			if (showMessage) {
				store.dispatch({type: actions.SHOW_LOGIN_CARD, values: {}})
				return
			}
			break
		case 400:
			console.log("error: 400 - bad request")
			break
		case 200:
			// console.log("code: 200 - success")
			break
		default:
			console.log(response.status)
	}
	if (!response.ok) {
		if (![401, 403, 406].includes(response.status) && showMessage) {
			connectionErrorMessage()
		}
		console.log('Network response was not ok')
		throw new Error("fetch error")
	}
}
/**
 *
 * @param {string} path
 * @param {Object} data
 * @param {boolean} showMessage
 * @param {boolean} getFromCache
 * @param {boolean} saveToCache
 * @returns {Promise<ServerResponse>}
 */
const jsonFetcher = async (path, data, showMessage = true, getFromCache = _getFromCache, saveToCache = _saveToCache) => {
	showLoader()
	if (!path) {hideLoader(); return new ServerResponse({status: SERVER_RESPONSE_STATE.BAD})}
	const _path = `${FETCHER_OPTIONS.JSON.ROOT}/${path}`
	const _init = {...FETCHER_OPTIONS.JSON.INIT, body: await JSON.stringify(data)}
	const request = new Request(_path, _init)
	try {
		const cache = await caches.open("paralian-data")
		let res = getFromCache ? await cache.match(_path) : undefined, fresh = false, resJ
		if (!res) {
			// connectionCheck()
			const connected = connectionCheck()
			if (!connected) {
				hideLoader()
				return new ServerResponse({status: SERVER_RESPONSE_STATE.BAD})
			}
			res = await fetch(request)
			// console.log("fetching " + path)
			handleFetchResponse(res, showMessage)
			resJ = await res.clone().json()
			fresh = true
		}	else {
			resJ = await res.clone().json()
			if (typeof resJ.data !== "object") {resJ = {data: {}}}
			if (!compareObjects(resJ.data, data)) {
				// connectionCheck()
				res = await fetch(request)
				// console.log("fetching " + path)
				handleFetchResponse(res, showMessage)
				resJ = await res.clone().json()
				fresh = true
			}
		}
		if (saveToCache && fresh && res.status === 200) {
			await cache.put(_path, res)
		}
		hideLoader()
		return new ServerResponse(resJ)
	} catch (e) {
		// console.log("jsonFetcher error", e)
		hideLoader()
		return new ServerResponse({status: SERVER_RESPONSE_STATE.BAD})
	}
}
/**
 *
 * @param {string} path
 * @param {FormData} formData
 * @returns {Promise<ServerResponse>}
 */
const fileUpload = async (path, formData) => {
	showLoader()
	// const _path = `${FETCHER_OPTIONS.JSON.ROOT}${path}`
	// const _init = {...FETCHER_OPTIONS.JSON.INIT, body: await JSON.stringify({a: "a"})}

	const _path = `${FETCHER_OPTIONS.FILE_UPLOAD.ROOT}/${path}`
	const _init = {...FETCHER_OPTIONS.FILE_UPLOAD.INIT, body: formData}
	const request = new Request(_path, _init)
	// console.log(request)
	connectionCheck()
	try {
		const res = await fetch(request)
		handleFetchResponse(res)
		hideLoader()
		return new ServerResponse(await res.json())
	} catch (e) {
		console.log(e)
		hideLoader()
		return new ServerResponse({status: SERVER_RESPONSE_STATE.BAD})
	}
}

export const genericGetFetcher = apiPath => {
	// console.log("generic get fetcher called")
	connectionCheck()
	return new Promise((resolve, reject) => {
		showLoader()
		fetch(apiPath, {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		}).then(response => {
				hideLoader()
				resolve(response.json())
			},
			reason => {
				hideLoader()
				console.log(reason)
				reject(reason)
		})
	})
}

/**
 *
 * @param {string|number} input - a username, login email, login phone
 * @param {string} type - LOGIN_TYPE
 * @returns {Promise<ServerResponse>}
 */
export const checkUsername = async (input, type = LOGIN_TYPE.USERNAME) => {
	return await jsonFetcher(getLoggedIn() ? "restricted/linked/login/input/verify" : "public/login/input/verify",
		{input: input, type: type},
		true, false, false)
}
/**
 *
 * @param {string} password
 * @returns {Promise<ServerResponse>}
 */
export const checkPassword = async password => {
	return await jsonFetcher("login", {password: password},
		true, false, false)
}
/**
 *
 * @param {number} id - account id
 * @returns {Promise<ServerResponse>}
 */
export const selectAccount = async id => {
	return await jsonFetcher("private/account/select", {target: {account: id}},
		true, false, false)
}
/**
 *
 * @param {boolean} getFromCache
 * @returns {Promise<ServerResponse>}
 */
export const getCurrentAccount = async (getFromCache = true) => {
	const path = "restricted/account/profile/get"
	const target = {target: {account: -1}}
	return await jsonFetcher(path, target, true, getFromCache, true)
}
/**
 *
 * @param {boolean} getFromCache
 * @param {number} [id = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const getOrganization = async (getFromCache = true, id = -1) => {
	return await jsonFetcher("restricted/organization/profile/get", {target: {organization: id}},
		false, getFromCache, true)
}
/**
 *
 * @param {boolean} getFromCache
 * @param {number} [id = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const getOrganizationPublicProfile = async (getFromCache = true, id = -1) => {
	const path = "public/organization/profile/get"
	const target = {target: {organization: id}}
	return await jsonFetcher(path, target, true, getFromCache, true)
}
/**
 *
 * @param {boolean} getFromCache
 * @param {number} [id = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const getCustomers = async (getFromCache = true, id = -1) => {
	const path = "restricted/organization/customers/get"
	const target = {target: {organization: id}}
	return await jsonFetcher(path, target, true, getFromCache, true)
}
/**
 *
 * @param {{id: number, isTemporary: boolean}[]} params
 * @returns {Promise<ServerResponse>}
 */
export const addVesselsAsCustomers = async params => {
	const path = "private/organization/customer/vessels/insert"
	return await jsonFetcher(path, {vessels: params, target: {organization: -1}},
		true, false, false)
}
/**
 *
 * @param {Object} identifiers
 * @param {number} [identifiers.imo]
 * @param {string} [identifiers.hin]
 * @param {number} [identifiers.mmsi]
 * @param {string} [identifiers.callSign]
 * @param {string} [identifiers.registration]
 * @param {number} [identifiers.officialNumber]
 * @returns {Promise<ServerResponse>}
 */
export const findVessel = async identifiers => {
	const path = `restricted/vessel/limited/profile/get`
	const data = {identifiers: identifiers}
	return await jsonFetcher(path, data, true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {Emails} [params.emails]
 * @param {Phones} [params.phones]
 * @returns {Promise<ServerResponse>}
 */
export const getVesselsByOwner = async params => {
	const path = `restricted/vessels/by/owner/limited/profiles/get`
	const data = {owner: params}
	return await jsonFetcher(path, data, true, false, false)
}
/**
 *
 * @param {string[]} identifiers - VESSEL_IDENTIFIER
 * @param {number} vessel - vessel id
 * @returns {Promise<ServerResponse>}
 */
export const submitVesselIdentifierSupportTicket = async (vessel, identifiers) => {
	const path = `restricted/vessel/identifier/support/ticket/create`
	const data = {vesselId: vessel, identifiers: identifiers}
	return await jsonFetcher(path, data, true, false, false)
}
/**
 *
 * @param {boolean} getFromCache
 * @param {number} [id = -1] - target id
 * @param {boolean} [isPersonal = true]
 * @returns {Promise<ServerResponse>}
 */
export const getVessels = async (getFromCache = true, isPersonal = true, id = -1) => {
	const path = `restricted/${isPersonal ? "account" : "organization"}/vessels/profiles/get`
	const target = isPersonal ? {target: {account: id}} :	 {target: {organization: id}}
	return await jsonFetcher(path, target, true, getFromCache, true)
}
/**
 *
 * @param {boolean} getFromCache
 * @param {number} [id = -1] - target id
 * @param {boolean} [isPersonal = true]
 * @returns {Promise<ServerResponse>}
 */
export const getMarinas = async (getFromCache = true, isPersonal = true, id = -1) => {
	const path = `restricted/${isPersonal ? "account" : "organization"}/marinas/profiles/get`
	const target = isPersonal ? {target: {account: id}} :	 {target: {organization: id}}
	return await jsonFetcher(path, target, true, getFromCache, true)
}
/**
 *
 * @param {boolean} getFromCache
 * @param {number} [id = -1] - target id
 * @returns {Promise<ServerResponse>}
 */
export const getOrders = async (getFromCache = true, id = -1) => {
	const path = "restricted/orders/get"
	const target = {target: {account: id}}
	return await jsonFetcher(path, target, true, getFromCache, true)
}
/**
 *
 * @param {number} id
 * @param {string} button
 * @returns {Promise<ServerResponse>}
 */
export const editOrder = async (id, button) =>
	await jsonFetcher("order/edit", {target: {order: id}, button: button},
		true, false, false)
/**
 *
 * @param {number} id
 * @param {Rating} rating
 * @returns {Promise<ServerResponse>}
 */
export const rateOrder = async (id, rating) =>
	await jsonFetcher("order/rate", {target: {order: id}, rating: rating},
		true, false, false)
/**
 *
 * @param {number} id
 * @param {number} employee - employee account id
 * @returns {Promise<ServerResponse>}
 */
export const assignOrder = async (id, employee) =>
	await jsonFetcher("order/assign", {target: {order: id}, employee: employee},
		true, false, false)
/**
 *
 * @param {number} id
 * @param {Object[]} items
 * @param {number} items.id
 * @param {number} items.quantity
 * @param {number} items.unitPrice
 * @param {string} message
 * @param {boolean} lockPrices
 * @param {boolean} lockRecurrence
 * @returns {Promise<ServerResponse>}
 */
export const adjustOrderPrice = async (id, items, message, lockPrices, lockRecurrence) =>
	await jsonFetcher("order/adjust/price", {target: {order: id}, items: items, message: message, lockPrices: lockPrices, lockRecurrence: lockRecurrence},
		true, false, false)
/**
 *
 * @param {number} id
 * @param {Object} dueDate
 * @param {string} dueDate.date
 * @param {string} dueDate.hour
 * @param {string} dueDate.minute
 * @param {string} dueDate.xm
 * @param {string} message
 * @returns {Promise<ServerResponse>}
 */
export const adjustOrderDueDate = async (id, dueDate, message) =>
	await jsonFetcher("order/adjust/duedate", {target: {order: id}, dueDate: dueDate, message: message},
		true, false, false)
/**
 *
 * @param {number} id
 * @param {number} refund
 * @param {string} message
 * @returns {Promise<ServerResponse>}
 */
export const refundOrder = async (id, refund, message) =>
	await jsonFetcher("order/refund", {target: {order: id}, refund: refund, message: message},
		true, false, false)
/**
 *
 * @param {number} id
 * @param {string} paymentMethod
 * @returns {Promise<ServerResponse>}
 */
export const confirmOrder = async (id, paymentMethod) =>
	await jsonFetcher("order/confirm", {target: {order: id}, paymentMethod: paymentMethod},
		true, false, false)
/**
 *
 * @param {boolean} getFromCache
 * @returns {Promise<ServerResponse>}
 */
export const getNotifications = async (getFromCache = true) => {
	const path = "restricted/notifications/get"
	const target = {target: {account: -1}}
	return await jsonFetcher(path, target, true, getFromCache, true)
}
/**
 *
 * @param {number} notification - notification id
 * @returns {Promise<ServerResponse>}
 */
export const deleteNotification = async notification => {
	const path = "private/notification/delete"
	const data = {target: {account: -1}, notification: notification}
	return await jsonFetcher(path, data, true, false, false)
}
/**
 * Logs user out of the account and deletes cache, local storage, session storage, cookies
 */
export const logOut = () => {
	const levelOne = store.getState().window.current.levelOne
	jsonFetcher("public/logout", {},
		false, false, false).catch(reason => console.log(reason))
	deleteCache("paralian-data").then(() => {
		localStorage.clear()
		sessionStorage.clear()
		setLoggedIn(false)
		store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: {levelOne: levelOne,
					levelTwo: "", levelThree: "", levelFour: "", levelFive: "", levelSix: ""}}})
	})
}

export const getUserQR = async () => {
	return await jsonFetcher("restricted/qrCode", {a: "a"},
		true, false, false)
}

export const getNoaaTextForecast = (location) => {
	const loc = location ? location : store.getState().device.location
	return new Promise((resolve, reject) => {
		genericGetFetcher(`https://api.weather.gov/points/${loc.latitude},${loc.longitude}`)
			.then(response => genericGetFetcher(`https://api.weather.gov/products/types/CWF/locations/${response.properties.cwa}`))
			.then(response => genericGetFetcher(response['@graph'][0]['@id']))
			.then(response => {
				const text = response.productText.replace(/000/g, '')
					.replace(/-\n/g, '.\t') // prep to maintain newline between comments
					.replace(/ \n/g, ' ')
					.replace(/\n /g, ' ')
					.replace(/\.\n\./g, '\t')
					.replace(/\n\n\./g, '\t')
					.replace(/\n\n/g, '\t')
					.replace(/\.\.\./g, '\t')
					.replace(/\.\./g, '\t')
					.replace(/([a-z])\n([a-z])/g, '$1 $2')
					.replace(/([0-9])\n([a-z])/g, '$1 $2')
					.replace(/([a-z])\n([0-9])/g, '$1 $2')
					.replace(/\.\n/g, ' ')
					.replace(/\t/g, '\n\n')
					.split('$$')
				;
				const headers = response.productText.replace(/([a-z])\n([a-z])/g, '$1 $2')
					.replace(/([0-9])\n([a-z])/g, '$1 $2')
					.replace(/([a-z])\n([0-9])/g, '$1 $2')
					.match(/-\n(.*?)-\n/g);
				for (let i = 0; i < headers.length; i++) {
					headers[i] = headers[i].replace(/-/g,'').replace(/\n/g,'')
				}
				resolve({productText: text, headers: headers})
			}).catch(reason => {console.log(reason); reject(reason)})
	})
}
/**
 *
 * @param {string} password
 * @returns {Promise<ServerResponse>}
 */
export const linkAccount = async password => {
	return await jsonFetcher("private/account/link", {password: password},
		true, false, false)
}
/**
 *
 * @param {number} marina - marina id
 * @param {number} account - account id
 * @returns {Promise<ServerResponse>}
 */
export const addFavoriteMarina = async (marina, account = -1) => {
	return await jsonFetcher("private/account/favorite/marina/insert",
		{target: {account: account}, marina: marina},
		true, false, false)
}
export const switchAccount = async id => {
	return await jsonFetcher("private/account/select", {target: {account: id}},
		true, false, false)
	/* TODO: "are you sure you want to switch account?", send fetch request */
}
/**
 *
 * @param {number} id - account id
 * @returns {Promise<ServerResponse>}
 */
export const deLinkAccount = async id => {
	return await jsonFetcher("private/account/delink", {target: {account: id}},
		true, false, false)
}
/**
 *
 * @param {number} id - marina id
 * @returns {Promise<ServerResponse>}
 */
export const deLinkMarina = async id => {
	return await jsonFetcher("private/account/favorite/marina/delete", {marina: id},
		true, false, false)
}
export const linkVessel = async id => {
	return await jsonFetcher("private/linked/add", {target: {vessel: id}},
		true, false, false)
}
/**
 *
 * @param {string} name
 * @param {string} brand
 * @param {string} model
 * @param {number} year
 * @param {boolean} [isOrgVessel = false]
 * @param {boolean} [isInvite = false]
 * @param {Object} [owner]
 * @returns {Promise<ServerResponse>}
 */
export const addVessel = async (name, brand, model, year, isOrgVessel = false, isInvite = false, owner = {}) => {
	const _data_ = {vessel: {name: name, brand: brand, model: model, year: year}, isInvite: isInvite, owner: owner}
	const _data = isOrgVessel ? {..._data_, target: {organization: -1}} : _data_
	return await jsonFetcher("private/vessel/insert", _data,
	 true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {string} params.vessel
 * @param {string} params.vessel.name
 * @param {string} params.vessel.brand
 * @param {string} params.vessel.model
 * @param {number} params.vessel.year
 * @param {Object} params.owner
 * @param {Object} params.owner.name
 * @param {Emails} params.owner.emails
 * @param {Phones} params.owner.phones
 * @param {boolean} params.owner.isOrganization
 * @param {string} params.action
 * @returns {Promise<ServerResponse>}
 */
export const createCustomerVessel = async params => {
	const data = {...params, target: {organization: -1}}
	return await jsonFetcher("/api/private/customer/vessel/insert", data, true, false, false)
}
export const setPreferredLanguage = async language => {
	return await jsonFetcher("restricted/account/language/edit", {language: language}, false)
}

export const setPreferredUnits = async units => {
	return await jsonFetcher("restricted/account/units/edit", {units: units})
}

export const setPreferredTimeFormat = async time => {
	return await jsonFetcher("restricted/account/use24hr/edit", {time: time})
}
/**
 *
 * @param {Blob} _image
 * @param {string} _type - IMAGE_TYPE
 * @param {number} _id
 * @returns {Promise<ServerResponse>}
 */
export const uploadImage = async (_image, _type, _id = -1) => {
	const formData = new FormData()
	formData.append("file", _image, `${Date.now()}.png`)
	formData.append("type", _type)
	let target
	switch (_type) {
		case IMAGE_CATEGORY.ACCOUNT_PROFILE:
			target = {account: _id}
			break
		case IMAGE_CATEGORY.VESSEL_PROFILE:
			target = {vessel: _id}
			break
		case IMAGE_CATEGORY.ORGANIZATION_ICON:
			target = {organization: _id}
			break
		case IMAGE_CATEGORY.ORGANIZATION_LOGO:
			target = {organization: _id}
			break
		case IMAGE_CATEGORY.ORGANIZATION_IMAGE:
			target = {organization: _id}
			break
		default:
			return new ServerResponse({status: SERVER_RESPONSE_STATE.BAD})
	}
	// formData.append(idType, _id)
	formData.append("target", JSON.stringify(target))
	// const response = await fileUpload("private/image/upload", formData)
	// console.log(response)
	// return response
	return await fileUpload("image/upload", formData)
}
/**
 *
 * @param {FormData} formData
 * @param {string} [action = "upload"]
 * @returns {Promise<ServerResponse>}
 */
export const uploadDocument = async (formData, action = "upload") => {
/*
	for (let key of formData.keys()) {
		console.log(key)
	}
	for (let value of formData.values()) {
		console.log(value)
	}
*/
	return await fileUpload(`document/${action}`, formData)
}

export const checkLoginStatus = async (showMessage = true) => {
	const response = await jsonFetcher("restricted/checkLoginStatus", {a: "a"}, showMessage,
		false, false)
	if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
		setLoggedIn(true)
		return true
	} else {
		setLoggedIn(false)
		return false
	}
}
/**
 *
 * @param {number} id - document id
 * @returns {Promise<ServerResponse>}
 */
export const deleteDocument = async id => {
	return await jsonFetcher("private/document/delete", {target: {document: id}},
		true, false, false)
}
/**
 *
 * @param {number} id - document id
 * @param {boolean|null} sms
 * @param {boolean|null} email
 * @returns {Promise<ServerResponse>}
 */
export const setDocRenewalReminder = async (id, sms, email) => {
	return await jsonFetcher(`private/document/renewal/reminder/edit`,
		{target: {document: id}, sms: sms, email: email},
		true, false, false)
}
/**
 *
 * @param password
 * @return {Promise<ServerResponse>}
 */
export const sendPasswordResetRequest = async password => {
	return await jsonFetcher("login/password/reset", {password: password},
		true, false, false)
}
/**
 *
 * @param {string|number} code
 * @param {string} type - VERIFICATION_TYPE
 * @param {string} pathRoot - private/limited/login/public
 * @param {boolean} isOrganization
 * @returns {Promise<ServerResponse>}
 */
export const sendVerificationCodeRequest = async (code, type, pathRoot, isOrganization = false) => {
	return await jsonFetcher(`${pathRoot}/code/verify`,
		{code: code, type: type, target: {organization: isOrganization ? -1 : null}},
		true, false, false)
}
/**
 *
 * @param {string|number} input - a username, login email, login phone
 * @param {string} type - LOGIN_TYPE
 * @returns {Promise<ServerResponse>}
 */
export const sendAccountUnlockRequest = async (input, type = LOGIN_TYPE.USERNAME) => {
	return await jsonFetcher("public/account/unlock/request/submit",
		{input: input, type: type},
		true, false, false)
}

export const newUser = async (userName, password, type) => {
	return await jsonFetcher("public/newUser", {userName: userName, password: password, type: type},
		true, false, false)
}
/**
 *
 * @param {string} password
 * @returns {Promise<ServerResponse>}
 */
export const storePassword = async password => {
	return await jsonFetcher("limited/password/insert", {password: password},
		true, false, false)
}
/**
 *
 * @param {Object} parameters
 * @param {number} parameters.tos - terms of service id
 * @param {string} parameters.response - RESPONSE
 * @return {Promise<ServerResponse>}
 */
export const sendTermsConditionsPoliciesAcceptance = async parameters => {
	return await jsonFetcher("limited/tcp/accept", {tos: parameters.tos, response: parameters.response},
		true, false, false)
}
/**
 *
 * @return {Promise<ServerResponse>}
 */
export const getTermsAndConditions = async () => {
	return await jsonFetcher("public/tos/get", {date: new Date().toLocaleDateString()},
		true, true, true)
}
/**
 * @param {Address} address
 * @returns {Promise<ServerResponse>}
 */
export const getLatLon = async address => {
	return await jsonFetcher("restricted/location/coordinates/get",
		{address: address}, true, true, true)
}
/**
 * @param {Coordinates} coordinates
 * @param {string} language
 * @returns {Promise<ServerResponse>}
 */
export const getTimezone = async (coordinates, language) => {
	return await jsonFetcher("restricted/location/timezone/get",
		{coordinates: coordinates, language: language})
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const getAllCountries = async () => {
	return await jsonFetcher("public/countries/get", {})
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const getFacilities = async () => {
	return await jsonFetcher("public/facilities/get", {},
		true, true, true)
}
/**
 *
 * @param {string} state - state code
 * @param {Coordinates} coordinates
 * @param {number} distance - in meters
 * @returns {Promise<ServerResponse>}
 */
export const getParalianMarinas = async (state, coordinates, distance) => {
	return await jsonFetcher("restricted/paralian/marinas/get",
		{state: state, coordinates: coordinates, distance: distance},
		true, true, true)
}
/**
 *
 * @param {string} country - ISO-3 country code
 * @returns {Promise<ServerResponse>}
 */
export const getCountryStates = async country => {
	return await jsonFetcher("public/states/get", {country: country})
}
/**
 *
 * @param {string} name
 * @returns {Promise<ServerResponse>}
 */
export const setNewContactName = async name => {
	return await jsonFetcher("limited/name/edit", {name: name},
		true, false, false)
}
/**
 *
 * @param {string} dob - date of birth
 * @returns {Promise<ServerResponse>}
 */
export const setNewContactBirthdate = async dob => {
	return await jsonFetcher("limited/dob/edit", {dob: dob},
		true, false, false)
}

export const setBirthDate = async birthDate => {
	return await jsonFetcher("private/birthDate", {birthDate: birthDate})
}

export const checkUserNameAvailability = async username => {
	return await jsonFetcher("limited/username/availability", {username: username})
}
/**
 *
 * @param {string} username
 * @returns {Promise<ServerResponse>}
 */
export const storeUsername = async username => {
	return await jsonFetcher("limited/username/edit", {username: username},
		true, false, false)
}
/**
 *
 * @param {string} phrase - personal security phrase
 * @returns {Promise<ServerResponse>}
 */
export const storePersonalSecurityPhrase = async phrase => {
	return await jsonFetcher("limited/psp/edit", {phrase: phrase},
		true, false, false)
}
/**
 *
 * @param {string} type - LOGIN_TYPE
 * @param {string|number} a - login email OR country code of login phone
 * @param {string|number|null} [b = null] - national number of login phone
 * @param {string|number|null} [c = null] - country iso of login phone
 * @param {string} [language = "en"] - national number of login phone
 * @returns {Promise<ServerResponse>}
 */
export const newAccountContactInfo = async (type, a, b = null, c = null, language = "en") => {
	const accountType = getFromStorage("type", "session")
	const invited = getFromStorage("invited", "session")
	const data = {account: accountType, invited: invited, language: language, input: type}
	let path = "public/account/new"
	const _data = type === LOGIN_TYPE.LOGIN_EMAIL ? {email: {address: a}} : {phone: {countryCode: a, nationalNumber: b, iso: c}}
	return await jsonFetcher(path, {..._data, ...data}, true, false, false)
}
/**
 *
 * @param {string} type - CONTACT_INFO_TYPE
 * @param {string|number} a - email address or phone country code
 * @param {string|number|null} [b = null] - phone national number
 * @param {string|number|null} [c = null] - phone country iso
 * @param {boolean} [isOrgContactInfo = false]
 * @returns {Promise<ServerResponse>}
 */
export const requestContactInfoUpdate = async (type, a, b = null, c = null, isOrgContactInfo = false) => {
	let path = "private/"
	const _data_ = (type === CONTACT_INFO_TYPE.PRIMARY_EMAIL || type === CONTACT_INFO_TYPE.SECONDARY_EMAIL) ? {email: a} :
		{countryCode: a, nationalNumber: b, iso: c}
	const _data = isOrgContactInfo ? {..._data_, target: {organization: -1}} : _data_
	switch (type) {
		case CONTACT_INFO_TYPE.PRIMARY_EMAIL:
			path = path + "email/secondary/edit"
			break
		case CONTACT_INFO_TYPE.SECONDARY_EMAIL:
			path = path + "email/secondary/edit"
			break
		case CONTACT_INFO_TYPE.PRIMARY_PHONE:
			path = path + "phone/primary/edit"
			break
		case CONTACT_INFO_TYPE.SECONDARY_PHONE:
			path = path + "phone/secondary/edit"
			break
		default:
			return null
	}
	return await jsonFetcher(path, _data, true, false, false)
}
/**
 *
 * @param {Email} email
 * @param {boolean} ignoreLoginConflict
 * @param {boolean} [isOrgContactInfo = false]
 * @returns {Promise<ServerResponse>}
 */
export const requestPrimaryEmailUpdate = async (email, ignoreLoginConflict, isOrgContactInfo = false) => {
	const data = {email: email, ignoreLoginConflict: ignoreLoginConflict}
	if (isOrgContactInfo) {data.target = {organization: -1}}
	return await jsonFetcher("private/email/primary/edit",	data,
		true, false, false)
}
/**
 *
 * @param {Email} email
 * @param {boolean} [isOrgContactInfo = false]
 * @returns {Promise<ServerResponse>}
 */
export const requestSecondaryEmailUpdate = async (email, isOrgContactInfo = false) => {
	const data = {email: email}
	if (isOrgContactInfo) {data.target = {organization: -1}}
	return await jsonFetcher("private/email/secondary/edit", data,
		true, false, false)
}
/**
 *
 * @param {Phone} phone
 * @param {boolean} ignoreLoginConflict
 * @param {boolean} [isOrgContactInfo = false]
 * @param {string} [method = "SMS"] - NOTIFICATION_METHOD
 * @returns {Promise<ServerResponse>}
 */
export const requestPrimaryPhoneUpdate = async (phone, ignoreLoginConflict, isOrgContactInfo = false, method = "SMS") => {
	const data = {phone: phone, ignoreLoginConflict: ignoreLoginConflict, method: method}
	// console.log(data)
	if (isOrgContactInfo) {data.target = {organization: -1}}
	return await jsonFetcher("private/phone/primary/edit", data,
		true, false, false)
}
/**
 *
 * @param {Phone} phone
 * @param {boolean} [isOrgContactInfo = false]
 * @param {string} [method = "SMS"] - NOTIFICATION_METHOD
 * @returns {Promise<ServerResponse>}
 */
export const requestSecondaryPhoneUpdate = async (phone, isOrgContactInfo = false, method = "SMS") => {
	const data = {phone: phone, method: method}
	if (isOrgContactInfo) {data.target = {organization: -1}}
	return await jsonFetcher("private/phone/secondary/edit", data,
		true, false, false)
}
/**
 *
 * @param {string} website
 * @param {number} [id = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const requestMainWebsiteUpdate = async (website, id = -1) => {
	return await jsonFetcher("private/organization/website/main/update",
		{website: website, target: {organization: id}},
		true, false, false)
}

export const getContactInfoFromServer = async (account, organization, useCache = false) => {
	return await jsonFetcher("restricted/contactInfo/get", {target: {account: account, organization: organization}},
		true, useCache, useCache)
}
export const getWebsites = async organization => {
	return await jsonFetcher("restricted/websites/get", {target: {organization: organization}},
		true, true, true)
}
/**
 *
 * @param {string} birthdate
 * @returns {Promise<ServerResponse>}
 */
export const requestDateOfBirthUpdate = async birthdate => {
	return await jsonFetcher("private/account/birthdate/edit", {birthdate: birthdate},
		true, false, false)
}
/**
 *
 * @param {Address} address
 * @param {boolean} isOrgAddress
 * @return {Promise<ServerResponse>}
 */
export const addressAdd = async (address, isOrgAddress = false) => {
	const _data = isOrgAddress ? {target: {organization: -1}} : {target: {account: -1}}
	return await jsonFetcher("private/address/insert", {..._data, address: address},
		true, false, false)
}
/**
 *
 * @param {Address} address
 * @param {boolean} isOrgAddress
 * @return {Promise<ServerResponse>}
 */
export const addressEdit = async (address, isOrgAddress = false) => {
	const tgt = {address: address.id}
	const _data = isOrgAddress ? {target: {organization: -1, ...tgt}} : {target: {account: -1, ...tgt}}
	return await jsonFetcher("private/address/edit", {..._data, address: address},
		true, false, false)
}
/**
 *
 * @param {number} id - address id
 * @param {boolean} isOrgAddress
 * @returns {Promise<ServerResponse>}
 */
export const addressDelete = async (id, isOrgAddress = false) => {
	const _data = isOrgAddress ? {target: {organization: -1, address: id}} : {target: {account: -1, address: id}}
	return await jsonFetcher("private/address/delete", _data,
		true, false, false)
}
/**
 *
 * @param {string} password
 * @returns {Promise<ServerResponse>}
 */
export const loginRefresh = async password => {
	return await jsonFetcher("restricted/login/refresh", {password: password},
		true, false, false)
}

export const getMarinasList = async (firstOrderAdminDiv) => {
	const response =  await jsonFetcher("/restricted/marinas/get", {firstOrderAdminDiv: firstOrderAdminDiv})
	if (response) {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			return response.payload
		} else {
			return false
		}
	} else {
		return false
	}
}

export const registerOrganization = async (legal, display, type, services, structure = null, adminTitle = null, website = null) => {
	return await jsonFetcher("private/organization/business/new",
		{name: {legal: legal, display: display}, type: type, structure: structure, adminTitle: adminTitle,
		website: {path: website}, services: services, target: {organization: -1}},
		true, false, false)
}
/**
 *
 * @param {number} vessel
 * @param {number} account
 * @returns {Promise<ServerResponse>}
 */
export const setAccountDefaultVessel = async (vessel, account = -1) => {
	return await jsonFetcher("private/account/default/vessel/edit", {target: {vessel: vessel, account: account}},
		true, false, false)
}
/**
 *
 * @param {number} marina
 * @param {number} account
 * @returns {Promise<ServerResponse>}
 */
export const setAccountDefaultMarina = async (marina, account = -1) => {
	return await jsonFetcher("private/account/default/marina/edit", {marina: marina, target: {account: account}},
		true, false, false)
}
/**
 *
 * @param {string} name
 * @returns {Promise<ServerResponse>}
 */
export const setContactName = async name => {
	return await jsonFetcher("private/account/contact/name/edit", {name: name},
		true, false, false)
}
/**
 *
 * @param {boolean} locked
 * @returns {Promise<ServerResponse>}
 */
export const lockAccount = async locked => {
	return await jsonFetcher("private/account/lock", {lock: locked},
		true, false, false)
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const deleteAccount = async () => {
	return await jsonFetcher("private/account/delete", {},
		true, false, false)
}
/**
 *
 * @param {boolean} isPaused
 * @returns {Promise<ServerResponse>}
 */
export const editPauseListings = async isPaused => {
	return await jsonFetcher("private/organization/isPaused/edit", {isPaused: isPaused, target: {organization: -1}},
		true, false, false)
}
/**
 *
 * @param {boolean} isExempt
 * @returns {Promise<ServerResponse>}
 */
export const editSalesTaxExemptionsServices = async isExempt => {
	return await jsonFetcher("private/organization/tax/exemptions/services/edit",
		{isExempt: isExempt, target: {organization: -1}},
		true, false, false)
}
/**
 *
 * @param {boolean} isExempt
 * @returns {Promise<ServerResponse>}
 */
export const editSalesTaxExemptionsGoods = async isExempt => {
	return await jsonFetcher("private/organization/tax/exemptions/goods/edit",
		{isExempt: isExempt, target: {organization: -1}},
		true, false, false)
}
/**
 *
 * @param {string} username
 * @returns {Promise<ServerResponse>}
 */
export const updateUsername = async username => {
	return await jsonFetcher("private/account/username/edit", {username: username})
}
/**
 *
 * @param {string} password
 * @returns {Promise<ServerResponse>}
 */
export const changePassword = async password => {
	return await jsonFetcher("private/account/password/update/request", {password: password})
}
/**
 *
 * @param {string} code
 * @returns {Promise<ServerResponse>}
 */
export const updatePasswordCode = async code => {
	return await jsonFetcher("private/account/password/update/confirm", {code: code})
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const getVesselBrands = async () => {
	return await jsonFetcher("restricted/vessel/hull/brands/get", {a: "a"},
		false, true, true)
}
/**
 *
 * @param {string} brand
 * @returns {Promise<ServerResponse>}
 */
export const getVesselModels = async brand => {
	return await jsonFetcher("restricted/vessel/hull/models/get", {brand: brand},
		false, true, true)
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const getEngineBrands = async () => {
	return await jsonFetcher("restricted/vessel/engine/brands/get", {a: "a"})
}
/**
 *
 * @param {string} brand
 * @returns {Promise<ServerResponse>}
 */
export const getEngineModels = async brand => {
	return await jsonFetcher("restricted/vessel/engine/models/get", {brand: brand},
		false, true, true)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} marina - marina id
 * @param {string} facilityName
 * @param {number|null} facility - facility id, set to null when not available
 * @param {string} unitName
 * @param {number|null} unit - unit id, set to null when not available
 * @return {Promise<ServerResponse>}
 */
export const addVesselStorageUnit = async (vessel, marina, facilityName, facility, unitName, unit) => {
	return await jsonFetcher("private/vessel/storage/unit/insert",
		{
			target: {vessel: vessel},
			marina: marina,
			facility: {name: facilityName, id: facility},
			unit: {name: unitName, id: unit}
			},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} marina - marina id
 * @param {number} priority
 * @returns {Promise<ServerResponse>}
 */
export const deleteVesselStorageUnit = async (vessel, marina, priority) => {
	return await jsonFetcher("private/vessel/storage/unit/delete",
		{target: {vessel: vessel}, marina: marina, priority: priority},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} marina - marina id
 * @param {number} priority
 * @returns {Promise<ServerResponse>}
 */
export const setAsPrimaryVesselStorageUnit = async (vessel, marina, priority) => {
	return await jsonFetcher("private/vessel/storage/unit/setPrimary",
		{target: {vessel: vessel}, marina: marina, priority: priority},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} marina - marina id
 * @param {number} priority
 * @returns {Promise<ServerResponse>}
 */
export const setAsCurrentVesselStorageUnit = async (vessel, marina, priority) => {
	return await jsonFetcher("private/vessel/storage/unit/setCurrent",
		{target: {vessel: vessel}, marina: marina, priority: priority},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {string} number
 * @return {Promise<ServerResponse>}
 */
export const setVesselRegistrationNumber = async (id, number) => {
	return await jsonFetcher("private/vessel/identifier/registration/edit", {target: {vessel: id}, number: number},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {number} number
 * @return {Promise<ServerResponse>}
 */
export const setVesselDocumentationNumber = async (id, number) => {
	return await jsonFetcher("private/vessel/identifier/documentation/edit", {target: {vessel: id}, number: number},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {number} mmsi
 * @return {Promise<ServerResponse>}
 */
export const setVesselMMSI = async (id, mmsi) => {
	return await jsonFetcher("private/vessel/identifier/mmsi/edit", {target: {vessel: id}, number: mmsi},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {number} imo
 * @return {Promise<ServerResponse>}
 */
export const setVesselIMO = async (id, imo) => {
	return await jsonFetcher("private/vessel/identifier/imo/edit", {target: {vessel: id}, number: imo},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {string} callSign
 * @return {Promise<ServerResponse>}
 */
export const setVesselCallSign = async (id, callSign) => {
	return await jsonFetcher("private/vessel/identifier/callSign/edit", {target: {vessel: id}, number: callSign},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {string} brand
 * @param {string} model
 * @param {number} year
 * @return {Promise<ServerResponse>}
 */
export const setVesselHull = async (id, brand, model, year) => {
	return await jsonFetcher("private/vessel/hull/edit",
		{target: {vessel: id}, brand: brand, model: model, year: year},
		true, false, false)
}
/**
 *
 * @param {number} id - vessel id
 * @param {number} fuel - vessel hull fuel capacity in liters
 * @param {number} water - vessel hull clean water capacity in liters
 * @param {number} waste - vessel hull wastewater capacity in liters
 * @param {number} livewell - vessel hull livewell capacity in liters
 * @param {number} personsQuantity - vessel hull person quantity capacity
 * @param {number} personsWeight - vessel hull person weight capacity in kg
 * @param {number} load - vessel hull person + motor + gear weight capacity in kg
 * @param {number} motor - vessel hull motor power capacity in kW
 * @param {number} sleeps - vessel hull sleeping capacity
 * @returns {Promise<ServerResponse>}
 */
export const setHullCapacity = async (id, fuel, water, waste, personsQuantity, personsWeight, load, motor, sleeps, livewell) => {
	return await jsonFetcher("private/vessel/hull/capacity/edit",
		{target: {vessel: id}, fuel: fuel, water: water, waste: waste, livewell: livewell,
			persons: {quantity: personsQuantity, weight: personsWeight}, load: load, motor: motor, sleeps: sleeps},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {string} hin
 * @return {Promise<ServerResponse>}
 */
export const setVesselHIN = async (id, hin) => {
	return await jsonFetcher("private/vessel/hull/hin/edit", {target: {vessel: id}, hin: hin},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {number} length
 * @param {number} beam
 * @param {number} draught
 * @param {number} clearance
 * @return {Promise<ServerResponse>}
 */
export const setHullDimensions = async (id, length, beam, draught, clearance) => {
	return await jsonFetcher("private/vessel/hull/dimensions/edit",
		{target: {vessel: id}, length: length, beam: beam, draught: draught, clearance: clearance},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {number} weight
 * @param {number} length
 * @param {number} width
 * @param {number} draught
 * @param {number} clearance
 * @return {Promise<ServerResponse>}
 */
export const setStorageRequirements = async (id, weight, length, width, draught, clearance) => {
	return await jsonFetcher("private/vessel/storage/requirements/edit",
		{target: {vessel: id}, weight: weight, length: length, width: width, draught: draught, clearance: clearance},
		true, false, false)
}
/**
 *
 * @param {number} id
 * @param {number} weight
 * @return {Promise<ServerResponse>}
 */
export const setHullDryWeight = async (id, weight) => {
	return await jsonFetcher("private/vessel/hull/weight/edit", {target: {vessel: id}, weight: weight},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} engine - engine id
 * @param {string} label
 * @returns {Promise<ServerResponse>}
 */
export const setEngineLabel = async (vessel, engine, label) => {
	return await jsonFetcher("private/vessel/edit/engine/label",
		{target: {vessel: vessel}, engine: engine, label: label},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} engine - engine id
 * @param {string} brand - engine brand
 * @param {string} model - engine model
 * @param {number} year - engine model year
 * @returns {Promise<ServerResponse>}
 */
export const setEngineBmy = async (vessel, engine, brand, model, year) => {
	return await jsonFetcher("private/vessel/edit/engine/bmy",
		{target: {vessel: vessel}, engine: engine, brand: brand, model: model, year: year},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} engine - engine id
 * @param {string} serialNumber - engine serial number
 * @returns {Promise<ServerResponse>}
 */
export const setEngineSn = async (vessel, engine, serialNumber) => {
	return await jsonFetcher("private/vessel/edit/engine/sn",
		{target: {vessel: vessel}, engine: engine, serialNumber: serialNumber},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} engine - engine id
 * @param {string} modelNumber - engine model number
 * @returns {Promise<ServerResponse>}
 */
export const setEngineMn = async (vessel, engine, modelNumber) => {
	return await jsonFetcher("private/vessel/edit/engine/mn",
		{target: {vessel: vessel}, engine: engine, modelNumber: modelNumber},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} engine - engine id
 * @param {string} hours - engine hours
 * @returns {Promise<ServerResponse>}
 */
export const setEngineHrs = async (vessel, engine, hours) => {
	return await jsonFetcher("private/vessel/edit/engine/hrs",
		{target: {vessel: vessel}, engine: engine, hours: hours},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} engine - engine id
 * @param {string} engineClass - engine class
 * @param {string} fuel - engine fuel type
 * @param {string} rotation - engine rotation STANDARD/COUNTER_ROTATING
 * @param {number} power - engine power in watts
 * @param {number} weight - engine weight in kg
 * @returns {Promise<ServerResponse>}
 */
export const setEngineSpecs = async (vessel, engine, engineClass, fuel, rotation, power, weight) => {
	return await jsonFetcher("private/vessel/edit/engine/specs",
		{
			target: {vessel: vessel},
			engine: engine, class: engineClass, fuel: fuel, rotation: rotation, power: power,	weight: weight
		},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} engine - engine id
 * @returns {Promise<ServerResponse>}
 */
export const deleteEngine = async (vessel, engine) => {
	return await jsonFetcher("private/vessel/delete/engine",	{target: {vessel: vessel}, engine: engine},
		true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {string} label - engine label
 * @param {string} brand - engine brand
 * @param {string} model - engine model
 * @param {number} year - engine model year
 * @param {string} serialNumber - engine serial number
 * @param {string} modelNumber - engine model number
 * @param {string} classification - engine classification
 * @param {string} fuel - fuel type
 * @param {boolean} rotation - true if engine propeller is counter-rotating
 * @param {number} power - engine power in watts
 * @param {number} weight - engine weight in kg
 * @param {number} hours
 * @returns {Promise<ServerResponse>}
 */
export const addEngine = async (vessel, label, brand, model, year, serialNumber, modelNumber, classification, fuel,
	rotation, power, weight, hours) => {
	return await jsonFetcher("private/vessel/insert/engine",
		{
			target: {vessel: vessel},
			label: label, brand: brand, model: model, year: year, serialNumber: serialNumber, power: power, weight: weight,
			modelNumber: modelNumber, classification: classification, fuel: fuel, rotation: rotation,
			hours: hours},
		true, false, false)
}
/**
 *
 * @param {number} vessel
 * @param {string} type - access type
 * @param {Object|boolean} access
 * @param {number|null} [provider = null]
 * @param {number|null} [account = null] - account id
 * @param {number|null} [organization = null] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const vesselAccessEdit = async (vessel, type, access, account = null, organization = null, provider = null) => {
	const path = type === "ownership" ? access ? "private/vessel/ownership/grant" : "private/vessel/ownership/rescind" :
		`private/vessel/access/${type}/edit`
	const data = {target: {vessel: vessel},	account: account, organization: organization, access: access}
	if (type !== "ownership") {data.target.account = account; data.target.organization = organization}
	if (type === "orders") {data.provider = provider}
	if (type === "physical") {data.marina = provider}
	return await jsonFetcher(path, data, true, false, false)
}
/**
 *
 * @param {number} vessel
 * @param {string} type - access type
 * @param {Object} access
 * @param {number|null} [provider = null]
 * @param {string|null} [account = null] - account username
 * @param {string|null} [organization = null] - organization username
 * @returns {Promise<ServerResponse>}
 */
export const vesselAccessGrant = async (vessel, type, access, account = null, organization = null, provider = null) => {
	const path = type === "ownership" ? "private/vessel/ownership/grant" : `private/vessel/access/${type}/grant`
	const data = {target: {vessel: vessel}, account: account, organization: organization, access: access}
	if (type === "orders") {data.provider = provider}
	if (type === "physical") {data.marina = provider}
	return await jsonFetcher(path, data, true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {string} type - access type
 * @param {number|null} [account = null] - account id
 * @param {number|null} [organization = null] - organization id
 * @param {number|null} [provider = null] - provider/marina id
 * @returns {Promise<ServerResponse>}
 */
export const vesselAccessRescind = async (vessel, type, account = null, organization = null, provider = null) => {
	const path = type === "ownership" ? "private/vessel/ownership/rescind" : `private/vessel/access/${type}/rescind`
	const data = {target: {vessel: vessel, account: account, organization: organization}}
	if (type === "orders") {data.provider = provider}
	if (type === "physical") {data.marina = provider}
	return await jsonFetcher(path, data, true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {string} [params.code] - ownership confirmation code
 * @param {number} [params.vessel] - vessel id
 * @returns {Promise<ServerResponse>}
 */
export const vesselOwnershipConfirm = async params => {
	const path = "/api/private/vessel/ownership/confirm"
	const data = params.vessel ? {target: {vessel: params.vessel}} : {code: params.code}
	return await jsonFetcher(path, data, true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} i
 * @param {number} j
 * @returns {Promise<ServerResponse>}
 */
export const setRigForetriangle = async (vessel, i, j) => {
	return await jsonFetcher("private/vessel/edit/rig/foretriangle",
		{target: {vessel: vessel}, I: i, J: j}, true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} is
 * @param {number} js
 * @returns {Promise<ServerResponse>}
 */
export const setRigInnerForetriangle = async (vessel, is, js) => {
	return await jsonFetcher("private/vessel/edit/rig/innerforetriangle",
		{target: {vessel: vessel}, Is: is, Js: js},true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} p
 * @param {number} e
 * @returns {Promise<ServerResponse>}
 */
export const setRigMainsail = async (vessel, p, e) => {
	return await jsonFetcher("private/vessel/edit/rig/mainsail",
		{target: {vessel: vessel}, P: p, E: e},true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} py
 * @param {number} ey
 * @returns {Promise<ServerResponse>}
 */
export const setRigMizzen = async (vessel, py, ey) => {
	return await jsonFetcher("private/vessel/edit/rig/mizzen",
		{target: {vessel: vessel}, Py: py, Ey: ey},true, false, false)
}
/**
 *
 * @param {number} vessel - vessel id
 * @param {number} isp
 * @param {number} spl
 * @param {number} stl
 * @returns {Promise<ServerResponse>}
 */
export const setRigSpinnaker = async (vessel, isp, spl, stl) => {
	return await jsonFetcher("private/vessel/edit/rig/spinnaker",
		{target: {vessel: vessel}, ISP: isp, SPL: spl, STL: stl},true, false, false)
}
/**
 *
 * @param {number} [id = -1] - marina id
 * @returns {Promise<ServerResponse>}
 */
export const getMarinaStorageFacilities = async (id = -1) => {
	return await jsonFetcher("restricted/marina/vesselStorage/facilities/get",
		{target: {organization: id}}, false, true, true)
}
/**
 *
 * @param {number} [marina = -1] - marina id
 * @param {number} facility - facility id
 * @returns {Promise<ServerResponse>}
 */
export const getMarinaStorageUnits = async (facility, marina = -1) => {
	return await jsonFetcher("restricted/marina/vesselStorage/units/get",
		{target: {organization: marina}, facility: facility}, false, true, true)
}
/* TODO: fix this */
export const getAvailableStorageUnits = async () => {
	return await jsonFetcher("restricted/reservations/vesselStorage/units/get/available",
		JSON.parse(sessionStorage.getItem("form")))
}
/**
 * @param {boolean} [isOrg = false]
 * @returns {Promise<ServerResponse>}
 */
export const newStripeSetupIntent = async (isOrg = false) => {
	const target = isOrg ? {target: {organization: -1}} : {target: {account: -1}}
	return await jsonFetcher("private/payment/setup/intent/new", target,
		true, false, false)
}
/**
 * @param {boolean} [isOrg = false]
 * @param {string} id - payment method id
 * @returns {Promise<ServerResponse>}
 */
export const detachStripePaymentMethod = async (id, isOrg = false) => {
	const target = isOrg ? {organization: -1} : {account: -1}
	return await jsonFetcher("private/payment/method/detach", {id: id, target: target},
		true, false, false)
}
/**
 * @param {boolean} [isOrg = false]
 * @returns {Promise<ServerResponse>}
 */
export const getStripePaymentMethods = async (isOrg = false) => {
	const target = isOrg ? {target: {organization: -1}} : {target: {account: -1}}
	return await jsonFetcher("private/payment/method/list", target,
		true, false, false)
}
/**
 * @param {boolean} [isOrg = false]
 * @param {string} id - payment method id
 * @returns {Promise<ServerResponse>}
 */
export const updateStripeDefaultPaymentMethod = async (id, isOrg = false) => {
	const target = isOrg ? {organization: -1} : {account: -1}
	return await jsonFetcher("private/payment/method/default/set", {id: id, target: target},
		true, false, false)
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const stripeOnboardConnectAccount = async () => {
	return await jsonFetcher("private/organization/payment/account/new", {target: {organization: -1}},
		true, false, false)
}
/**
 *
 * @param {Object|null} params
 * @param {boolean} [params.isLive]
 * @param {boolean} [params.showHidden]
 * @param {boolean} [params.broker]
 * @param {boolean} [params.business]
 * @param {boolean} [params.marina]
 * @param {"SERVICES"|"GOODS"} [params.type]
 * @param {boolean} cache
 * @returns {Promise<ServerResponse>}
 */
export const getServices = async (params = null, cache = true) => {
	return await jsonFetcher("public/paralian/items/get", {params: params},
		true, cache, cache)
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const getDefaultPricingStructures = async () => {
	return await jsonFetcher("public/default/pricing/structures/get", {a: "a"},
		true, true, true)
}
/**
 * @param {Object} order
 * @param {Object[]} order.marinas
 * @param {number} order.marinas.id
 * @param {Object[]} order.vessels
 * @param {number} order.vessels.id
 * @param {Object} order.homes
 * @param {number} order.homes.priority
 * @param {Object[]} order.items
 * @param {number} order.items.id
 * @param {number} order.items.quantity
 * @param {string} order.startDate
 * @param {Time} order.startTime
 * @param {string} order.dueDate
 * @param {Time} order.dueTime
 * @param {string} order.message
 * @returns {Promise<ServerResponse>}
 */
export const getServiceProviders = async order => {
	// console.log({target: {vessel: order.vessels[0].id}, order: order})
	return await jsonFetcher("restricted/marina/service/providers/get",
		{target: {vessel: order.vessels[0].id}, order: order},
		true, true, true)
}
/**
 *
 * @param {string} country - ISO-3 country code
 * @returns {Promise<ServerResponse>}
 */
export const getHolidays = async country => {
	return await jsonFetcher("public/holidays/get", {country: country})
}
/**
 *
 * @param {string|null} [country] - ISO-3 country code
 * @returns {Promise<ServerResponse>}
 */
export const getCurrencies = async (country = null) => {
	return await jsonFetcher("public/currencies/get", {country: country})
}
/**
 *
 * @param {string} country - ISO-2 country code
 * @returns {Promise<ServerResponse>}
 */
export const getTimezones = async country => {
	return await jsonFetcher("public/timezones/get", {country: country})
}
/**
 *
 * @param {Coordinates} [coordinates] - search center coordinates
 * @param {number} [distance = null] - search radius in meters
 * @returns {Promise<ServerResponse>}
 */
export const getMarinasWithinRadius = async (coordinates = new Coordinates(), distance = 0) => {
	return await jsonFetcher("restricted/marinas/within/radius/get",
		{coordinates: coordinates, distance: distance}, true, true, true)
}
/**
 *
 * @param {number} [organization = -1]
 * @returns {Promise<ServerResponse>}
 */
export const getMarinasWithinOrgServiceArea = async (organization = -1) => {
	return await jsonFetcher("restricted/organization/marinas/within/radius/get",
		{target: {organization: organization}}, true, true, true)
}
/**
 * @param {number} service - service id
 * @param {Coordinates} [coordinates] - search center coordinates
 * @param {number} [distance = null] - search radius in meters
 * @returns {Promise<ServerResponse>}
 */
export const getBusinessesWithinRadius = async (service, coordinates = new Coordinates(), distance = 0) => {
	return await jsonFetcher("restricted/businesses/within/radius/get",
		{coordinates: coordinates, distance: distance, service: service})
}
/**
 *
 * @param {number} organization - organization id
 * @returns {Promise<ServerResponse>}
 */
export const getOrgOfferedServices = async (organization = -1) => {
	return await jsonFetcher("restricted/organization/services/offered/get",
		{target: {organization: organization}}, true, true, true)
}
/**
 *
 * @param {Coordinates} coordinates
 * @param {number} [organization = -1] - organization id
 * @return {Promise<ServerResponse>}
 */
export const updateOrgLocation = async (coordinates, organization = -1) => {
	return await jsonFetcher("private/organization/location/edit",
		{target: {organization: organization}, coordinates: coordinates},
		true, false, false)
}
/**
 *
 * @param {string} currency
 * @param {number} [organization = -1] - organization id
 * @return {Promise<ServerResponse>}
 */
export const updateOrgCurrency = async (currency, organization = -1) => {
	return await jsonFetcher("private/organization/currency/edit",
		{target: {organization: organization}, currency: currency},
		true, false, false)
}
/**
 *
 * @param {string} timezone
 * @param {number} [organization = -1] - organization id
 * @return {Promise<ServerResponse>}
 */
export const updateOrgTimezone = async (timezone, organization = -1) => {
	return await jsonFetcher("private/organization/timezone/edit",
		{target: {organization: organization}, timezone: timezone},
		true, false, false)
}
/**
 *
 * @param {Schedule} schedule
 * @param {number} [organization = -1] - organization id
 * @return {Promise<ServerResponse>}
 */
export const updateOrgSchedule = async (schedule, organization = -1) => {
	return await jsonFetcher("private/organization/schedule/edit",
		{target: {organization: organization}, schedule: schedule},
		true, false, false)
}
/**
 *
 * @param {Holiday[]} holidays
 * @param {number} [organization = -1] - organization id
 * @return {Promise<ServerResponse>}
 */
export const updateOrgHolidays = async (holidays, organization = -1) => {
	return await jsonFetcher("private/organization/holidays/edit",
		{target: {organization: organization}, holidays: holidays},
		true, false, false)
}
/**
 *
 * @param {number} id - holiday id
 * @param {number} [organization = -1] - organization id
 * @return {Promise<ServerResponse>}
 */
export const deleteOrgHoliday = async (id, organization = -1) => {
	return await jsonFetcher("private/organization/holiday/delete",
		{target: {organization: organization}, id: id},
		true, false, false)
}
/**
 *
 * @param {Object} param
 * @param {Object[]} param.employees
 * @param {string} param.employees[].email
 * @param {string} param.employees[].name
 * @param {Array} param.employees[].services
 * @param {number} param.employees[].services[].id
 * @param {boolean} param.employees[].services[].canAssign
 * @param {boolean} param.employees[].services[].canAcceptReject
 * @param {boolean} param.employees[].services[].canView
 * @param {number} [param.organization = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const addOrgEmployees = async param => {
	return await jsonFetcher("private/organization/employees/insert",
		{target: {organization: param.organization || -1}, employees: param.employees, language: getLanguage()},
		true, false, false)
}
/**
 *
 * @param {Employee} employee
 * @param {number} employee.id
 * @param {string} employee.title
 * @param {string} employee.status
 * @param {Object[]} employee.services
 * @param {number} employee.services[].id
 * @param {boolean} employee.services[].canAssign
 * @param {boolean} employee.services[].canAcceptReject
 * @param {boolean} employee.services[].canView
 * @returns {Promise<ServerResponse>}
 */
export const editOrgEmployee = async employee => {
	return await jsonFetcher("private/organization/employee/edit",
		{target: {account: employee.id}, title: employee.title, status: employee.status, services: employee.services},
		true, false, false)
}
/**
 *
 * @param {number} id - admin account id
 * @returns {Promise<ServerResponse>}
 */
export const deleteOrgAdmin = async id => {
	return await jsonFetcher("private/organization/admin/delete",
		{target: {account: id}},	true, false, false)
}
/**
 *
 * @param {number} radius - service area radius in meters
 * @param {string} country - country code, 3-letter
 * @param {string} state - state code
 * @param {number} [organization = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const updateOrgServiceArea = async (radius, country, state, organization = -1) => {
	return await jsonFetcher("private/organization/service/area/edit",
		{target: {organization: organization}, radius: radius, country: country, state: state},
		true, false, false)
}
/**
 *
 * @param {boolean} canVisit
 * @param {number} [organization = -1] - organization id
 * @return {Promise<ServerResponse>}
 */
export const updateOrgWelcomesVisitors = async (canVisit, organization = -1) => {
	return await jsonFetcher("private/organization/location/welcomes/visitors/edit",
		{target: {organization: organization}, canVisit: canVisit},
		true, false, false)
}
/**
 *
 * @param {Object[]} pricingSpecs
 * @param {number} pricingSpecs[].service - service id
 * @param {string} pricingSpecs[].service.type - pricing type BASIC/COMPOSITE,
 * @param {string} pricingSpecs[].service.message - in case type is COMPOSITE, description of pricing
 * @param {Object} pricingSpecs[].service.pricing
 * @param {string} pricingSpecs[].service.pricing.unit - pricing unit of measure, METER/FOOT
 * @param {number} pricingSpecs[].service.pricing.price - pricing price per unit
 * @param {number} pricingSpecs[].service.pricing.floor - pricing minimum charge
 * @param {boolean} [isActive = false] - set services and prices as viewable to customers
 * @param {number} [organization = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const updateOrgServicesPrices = async (pricingSpecs, isActive = false, organization = -1) => {
	return await jsonFetcher("private/organization/services/prices/edit",
		{target: {organization: organization}, pricingSpecs: pricingSpecs, isActive: isActive},
		true, false, false)
}
/**
 *
 * @param {string} source - address type being copied to field of destination's address type
 * @param {string} destination - address type of field on which source address type will be referenced
 * @param {number} [organization = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const matchOrgAddress = async (source, destination, organization = -1) => {
	return await jsonFetcher("private/organization/address/match",
		{target: {organization: organization}, source: source, destination: destination},
		true, false, false)
}
/**
 *
 * @param {number[]} marinas - array of marina ids
 * @param {number} [organization = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const addOrgServiceMarinas = async (marinas, organization = -1) => {
	return await jsonFetcher("private/organization/services/approval/requests/requester/insert",
		{target: {organization: organization}, marinas: marinas},
		true, false, false)
}
/**
 *
 * @param {number[]} marina - marina id
 * @param {number} [organization = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const cancelOrgAccessRequestsToMarina = async (marina, organization = -1) => {
	return await jsonFetcher("private/organization/services/approval/requests/requester/delete",
		{target: {organization: organization}, id: marina},
		true, false, false)
}
/**
 *
 * @param {number} organization - requester organization id
 * @returns {Promise<ServerResponse>}
 */
export const acceptAllOrgAccessRequestsToMarina = async organization => {
	return await jsonFetcher("private/organization/services/approval/requests/requestee/accept/all",
		{target: {organization: -1}, organization: organization}, true, false, false)
}
/**
 *
 * @param {number} organization - requester organization id
 * @param {number} category - item category id
 * @param {string} response - REQUEST_STATUS
 * @returns {Promise<ServerResponse>}
 */
export const respondToOrgAccessRequestsToMarina = async (organization, category, response) => {
	return await jsonFetcher("private/organization/services/approval/requests/requestee/respond",
		{target: {organization: -1}, organization: organization, category: category, response: response},
		true, false, false)
}
/**
 *
 * @param {Colors} colors
 * @param {number} [organization = -1] - organization id
 * @returns {Promise<ServerResponse>}
 */
export const updateOrgColors = async (colors, organization = -1) => {
	return await jsonFetcher("private/organization/colors/edit",
		{target: {organization: organization}, colors: colors},
		true, false, false)
}
/**
 *
 * @param {string} password
 * @return {Promise<ServerResponse>}
 */
export const resetPassword = async password => {
	return await jsonFetcher("private/password/reset", {password: password},
		true, false, false)
}
/**
 *
 * @param {string} token - subscription token
 * @returns {Promise<ServerResponse>}
 */
export const setPushSubscription = async token => {
	return await jsonFetcher("private/push/subscription/insert", {token: token},
		true, false, false)
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const deletePushSubscription = async () => {
	return await jsonFetcher("private/push/subscription/delete", {a: "a"},
		true, false, false)
}
/**
 *
 * @return {Promise<ServerResponse>}
 */
export const requestPersonalPremiumSubscription = async paymentMethod => {
	return await jsonFetcher("private/account/stripe/subscription/personal/premium/insert",
		{paymentMethod: paymentMethod},
		true, false, false)
}
/**
 * @param {string} paymentMethod
 * @return {Promise<ServerResponse>}
 */
export const updatePersonalPremiumSubscriptionPaymentMethod = async paymentMethod => {
	return await jsonFetcher("private/account/stripe/subscription/personal/premium/payment/method/update",
		{paymentMethod: paymentMethod},
		true, false, false)
}
/**
 * @param {boolean} cancel
 * @return {Promise<ServerResponse>}
 */
export const autoRenewPersonalPremiumSubscription = async cancel => {
	return await jsonFetcher("private/account/stripe/subscription/personal/premium/autoRenew",
		{cancel: cancel},
		true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {Object[]} params.items
 * @param {number} params.items.id
 * @param {number} params.items.quantity
 * @param {number} params.provider - provider id
 * @param {Object[]} params.vessels
 * @param {number} params.vessels.id
 * @param {Object[]} params.marinas
 * @param {number} params.marinas.id
 * @param {Object} params.home
 * @param {number} params.home.priority
 * @param {string} params.paymentMethod - payment method id
 * @param {string} params.dueDate
 * @param {Time} params.dueTime
 * @param {string} params.startDate
 * @param {Time} params.startTime
 * @param {string} params.message
 * @param {boolean} [params.captureOnConfirmPayment = false] - hold by default
 * @param {boolean} [params.isOrg = false]
 * @param {boolean} [params.isConcierge]
 * @return {Promise<ServerResponse>}
 */
export const createStripeConnectPaymentIntent = async params => {
	/* TODO: add special request */
	/* TODO: set account/organization target in on-behalf-of cases */
	const target = {vessel: params.vessels[0].id}
	if (params.isOrg) {
		target.organization = -1
	} else {
		target.account = -1
	}
	return await jsonFetcher("private/order/insert",
		{...params, target: target},
		true, false, false)
}
/**
 * @param {Object} params
 * @param {number} params.provider
 * @param {number} params.marina
 * @param {number} params.vessel
 * @param {number} params.account
 * @param {string} params.paymentMethod
 * @param {string} params.date
 * @param {Time} params.time
 *
 * @param {{id: number, quantity: number}[]} params.items
 * @return {Promise<ServerResponse>}
 */
export const insertConciergeOrder = async params => {
	return await jsonFetcher("concierge/order/insert",	params,
		true, false, false)
}
/**
 *
 * @return {Promise<ServerResponse>}
 */
export const getParalianProducts = async () => {
	return await jsonFetcher("restricted/paralian/products/pricing/get", {a: "a"},
		true, false, false)
}
/**
 *
 * @return {Promise<ServerResponse>}
 */
export const getParalianInvoices = async (refresh = false, isOrg) => {
	const target = isOrg ? {target: {organization: -1}} : {target: {account: -1}}
	return await jsonFetcher("restricted/paralian/invoices/get", target,
		true, !refresh, true)
}
/**
 *
 * @param {string} paymentMethod - payment method id
 * @param	{string} product - PARALIAN_PRODUCT_NAME
 * @param {boolean} isOrg
 * @return {Promise<ServerResponse>}
 */
export const purchaseParalianProduct = async (paymentMethod, product, isOrg) => {
	const target = isOrg ? {target: {organization: -1}} : {target: {account: -1}}
	return await jsonFetcher("private/paralian/product/purchase",
		{...target, product: product, paymentMethod: paymentMethod},
		true, false, false)
}
/**
 *
 * @param {string} id - sale id
 * @param {boolean} isOrg
 * @return {Promise<ServerResponse>}
 */
export const cancelParalianProduct = async (id, isOrg) => {
	const target = isOrg ? {target: {organization: -1}} : {target: {account: -1}}
	return await jsonFetcher("private/paralian/product/cancel",
		{...target, id: id}, true, false, false)
}
/**
 *
 * @param {string} id - sale id
 * @param {string} paymentMethod - payment method id
 * @param {boolean} isOrg
 * @return {Promise<ServerResponse>}
 */
export const updateParalianProductPaymentMethod = async (id, paymentMethod, isOrg) => {
	const target = isOrg ? {target: {organization: -1}} : {target: {account: -1}}
	return await jsonFetcher("private/paralian/product/payment/method/update",
		{...target, id: id, paymentMethod: paymentMethod}, true, false, false)
}
/**
 *
 * @param {number} subscription - paralian id
 * @param {string} product - stripeId
 * @param {string} limit
 * @param {boolean} isOrg
 * @return {Promise<ServerResponse>}
 */
export const updateParalianProductLimit = async (subscription, product, limit, isOrg) => {
	const target = isOrg ? {target: {organization: -1}} : {target: {account: -1}}
	return await jsonFetcher("private/paralian/product/limit/update",
		{...target, subscription: subscription, product: product, limit: limit},
		true, false, false)
}
/**
 *
 * @param {string} paymentMethod - paymentMethod id
 * @return {Promise<ServerResponse>}
 */
export const activateParalianProducts = async paymentMethod => {
	return await jsonFetcher("private/paralian/products/activate",
		{target: {organization: -1}, paymentMethod: paymentMethod},
		true, false, false)
}
/**
 *
 * @return {Promise<ServerResponse>}
 */
export const runTest = async () => {
	return await jsonFetcher("restricted/tester", {a: "a"}, true, false, false)
}
/**
 *
 * @return {Promise<ServerResponse>}
 */
export const logError = async (e, component = "COMPONENT") => {
	return await jsonFetcher("restricted/logs/client/crash",
		{error: {name: e.name, message: e.message}, component: component, device: getDeviceData()},
		false, false, false)
}
/**
 *
 * @param {string[]} selected
 * @param {number} organization
 * @returns {Promise<ServerResponse>}
 */
export const setPhysicalFacilitiesAndAmenities = async (selected, organization = -1) => {
	return await jsonFetcher("private/organization/facilities/aggregate/physical/edit",
		{target: {organization: organization}, selected: selected},
		true, false, false)
}
/**
 *
 * @param {Object[]} vsf - vessel storage facilities object array
 * @param {string} vsf.key
 * @param {number} vsf.quantity
 * @param {number} [vsf.weight] - in kg
 * @param {number} [vsf.length] - in m
 * @param {number} [vsf.width] - in m
 * @param {number} [vsf.height] - in m
 * @param {number} [vsf.draught] - in m
 * @param {number} [organization = -1]
 * @returns {Promise<ServerResponse>}
 */
export const setVesselStorageFacilities = async (vsf, organization = -1) => {
	return await jsonFetcher("private/organization/facilities/aggregate/vessel/storage/edit",
		{target: {organization: organization}, vsf: vsf},
		true, false, false)
}
/**
 *
 * @param {Object} share
 * @param {number} share.document
 * @param {string} share.idType - ID_TYPE
 * @param {number} share.id
 * @param {number} share.requirement
 * @returns {Promise<ServerResponse>}
 */
export const shareDocument = async share => {
	return await jsonFetcher("private/document/share",
		{target: {document: share.document}, share: share},
		true, false, false)
}
/**
 *
 * @param {Object} share
 * @param {number} share.document
 * @param {string} share.idType - ID_TYPE
 * @param {number} share.id
 * @param {number} share.requirement
 * @returns {Promise<ServerResponse>}
 */
export const unshareDocument = async share => {
	return await jsonFetcher("private/document/unshare",
		{target: {document: share.document}, share: share},
		true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {number} params.requirement
 * @param {number} [params.organization = -1]
 * @returns {Promise<ServerResponse>}
 */
export const deleteDocumentRequirement = async params => {
	return await jsonFetcher("private/document/requirement/delete",
		{target: {organization: params.organization || -1}, requirement: params.requirement},
		true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {number} params.requirement
 * @param {number} params.document
 * @param {number} [params.organization = -1]
 * @returns {Promise<ServerResponse>}
 */
export const deleteDocumentRequirementDocument = async params => {
	return await jsonFetcher("private/document/requirement/document/delete",
		{target: {organization: params.organization || -1, document: params.document}, requirement: params.requirement},
		true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {number} params.requirement
 * @param {number} params.document
 * @param {number} [params.organization = -1]
 * @returns {Promise<ServerResponse>}
 */
export const replaceDocumentRequirementDocument = async params => {
	return await jsonFetcher("private/document/requirement/document/replace",
		{target: {organization: params.organization || -1, document: params.document}, requirement: params.requirement},
		true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {number} params.requirement
 * @param {number} params.label
 * @param {number} [params.organization = -1]
 * @returns {Promise<ServerResponse>}
 */
export const renameDocumentRequirement = async params => {
	return await jsonFetcher("private/document/requirement/label/update",
		{target: {organization: params.organization || -1}, requirement: params.requirement, label: params.label},
		true, false, false)
}
/**
 *
 * @param {number} order - id
 * @returns {Promise<ServerResponse>}
 */
export const addProviderToFavorites = async order => {
	return await jsonFetcher("order/provider/favorites/insert",
		{target: {order: order}}, true, false, false)
}
/**
 *
 * @param {number} vessel - id
 * @returns {Promise<ServerResponse>}
 */
export const deleteVesselRegistrationNumber = async vessel => {
	return await jsonFetcher("private/vessel/identifier/registration/delete",
		{target: {vessel: vessel}}, true, false, false)
}
/**
 *
 * @param {number} vessel - id
 * @returns {Promise<ServerResponse>}
 */
export const deleteVesselDocumentationNumber = async vessel => {
	return await jsonFetcher("private/vessel/identifier/documentation/delete",
		{target: {vessel: vessel}}, true, false, false)
}
/**
 *
 * @param {number} vessel - id
 * @returns {Promise<ServerResponse>}
 */
export const deleteVesselMMSI = async vessel => {
	return await jsonFetcher("private/vessel/identifier/mmsi/delete",
		{target: {vessel: vessel}}, true, false, false)
}
/**
 *
 * @param {number} vessel - id
 * @returns {Promise<ServerResponse>}
 */
export const deleteVesselIMO = async vessel => {
	return await jsonFetcher("private/vessel/identifier/imo/delete",
		{target: {vessel: vessel}}, true, false, false)
}
/**
 *
 * @param {number} vessel - id
 * @returns {Promise<ServerResponse>}
 */
export const deleteVesselCallSign = async vessel => {
	return await jsonFetcher("private/vessel/identifier/callSign/delete",
		{target: {vessel: vessel}}, true, false, false)
}
/**
 *
 * @param {number} vessel - id
 * @returns {Promise<ServerResponse>}
 */
export const deleteVesselHIN = async vessel => {
	return await jsonFetcher("private/vessel/hull/hin/delete",
		{target: {vessel: vessel}}, true, false, false)
}
/**
 *
 * @returns {Promise<ServerResponse>}
 */
export const getVersion = async () => {
	return await jsonFetcher("public/service/worker/version/get", {a: "a"},
		false, false, false)
}
/**
 *
 * @param {Object} params
 * @param {number} params.vessel
 * @param {string} params.state
 * @return {Promise<ServerResponse>}
 */
export const setCustomerVesselState = async params => {
	return await jsonFetcher("private/organization/customer/vessel/state/edit",
		{target: {organization: -1}, vessel: params.vessel, state: params.state},
		false, false, false)
}
/**
 *
 * @param {Object} params
 * @param {Object[]} params.vessels
 * @param {number} params.vessels.id
 * @param {Object[]} params.marinas
 * @param {number} params.marinas.id
 * @param {string} params.message
 * @param {Object} params.contact
 * @param {string} params.contact.address
 * @param {string} params.contact.method
 * @return {Promise<ServerResponse>}
 */
export const sendConciergeServiceRequest = async params => {
	return await jsonFetcher("private/order/concierge/service/insert",
		{
			target: {vessel: params.vessels[0].id},
			marina: params.marinas[0].id,
			request: params.message,
			contact: params.contact
		},
		true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {number} params.id
 * @param {string} params.recurrence
 * @param {number} params.frequency
 * @param {string} params.day
 * @param {string} params.month
 * @param {string} params.weekday
 * @param {string[]} params.weekdays
 * @param {number} params.date
 * @param {boolean} params.forceAutoBill
 * @param {boolean} params.autoBill
 * @param {string} params.next
 * @param {string} params.expires
 * @param {number} params.termQuantity
 * @param {string} params.termUnits
 * @param {boolean} params.allowAutoRenew
 * @param {boolean} params.autoRenew
 * @param {number} params.renewalPremium
 * @param {boolean} params.excludeWeekends
 * @param {boolean} params.excludeWeekdays
 * @param {boolean} params.prepaid
 * @param {boolean} params.lockPrice
 * @return {Promise<ServerResponse>}
 */
export const setOrderRecurrence = async params => {
	return await jsonFetcher("order/recurrence/enter",
		{target: {order: params.id}, button: ORDER_BUTTON.MAKE_RECURRING, recurrence: params},
		true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {number} params.id
 * @param {string} params.recurrence
 * @param {number} params.frequency
 * @param {string} params.day
 * @param {string} params.month
 * @param {string} params.weekday
 * @param {string[]} params.weekdays
 * @param {number} params.date
 * @param {boolean} params.forceAutoBill
 * @param {boolean} params.autoBill
 * @param {string} params.next
 * @param {string} params.expires
 * @param {number} params.termQuantity
 * @param {string} params.termUnits
 * @param {boolean} params.allowAutoRenew
 * @param {boolean} params.autoRenew
 * @param {number} params.renewalPremium
 * @param {boolean} params.excludeWeekends
 * @param {boolean} params.excludeWeekdays
 * @param {boolean} params.prepaid
 * @param {boolean} params.lockPrice
 * @return {Promise<ServerResponse>}
 */
export const editOrderRecurrence = async params => {
	return await jsonFetcher("order/recurrence/edit",
		{target: {order: params.id}, button: ORDER_BUTTON.EDIT_RECURRENCE, recurrence: params},
		true, false, false)
}
/**
 *
 * @param {Object} params
 * @param {number} params.id
 * @return {Promise<ServerResponse>}
 */
export const cancelOrderRecurrence = async params => {
	return await jsonFetcher("order/recurrence/cancel",
		{target: {order: params.id}, button: ORDER_BUTTON.CANCEL, recurrence: params},
		true, false, false)
}