import React, {useEffect, useState} from "react"
import {MONTH, RECURRENCE, SERVER_RESPONSE_STATE, WEEKDAY} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {
	dateToYYYYMMDD, fadingMessage,
	getDateDelta,
	getTimeFormat,
	newMessage,
	stringDateAndTimeToDate,
	translate
} from "../../../../../globals/functions/client/localFunctions"
import {editOrderRecurrence, setOrderRecurrence} from "../../../../../globals/functions/client/serverFunctions"
import Modal from "../../../../../globals/components/Modal"
import MobileOrdinalsDropdown from "../../../../shared/input/dropdown/MobileOrdinalsDropdown"
import MobileDaysDropdown from "../../../../shared/input/dropdown/MobileDaysDropdown"
import MobileMonthDropdown from "../../../../shared/input/dropdown/MobileMonthDropdown"
import MobileNumberRangeDropdown from "../../../../shared/input/dropdown/MobileNumberRangeDropdown"
import MobileRecurrenceDropdown from "../../../../shared/input/dropdown/MobileRecurrenceDropdown"
import MobileInputForm from "../../../../shared/input/forms/MobileInputForm"
import MobileInputDate from "../../../../shared/input/text/MobileInputDate"
import MobileFontAwesome from "../../../MobileFontAwesome"
import MobileTimeField from "../../../calendar/MobileTimeField"
import Time from "../../../../../globals/classes/shared/Time"
/**
 *
 * @param {Object} props
 * @param {Object} props.order.recurrence
 * @param {'DAILY','WEEKLY','MONTHLY','YEARLY','ONCE'} props.order.recurrence.recurrence
 * @param {number} props.order.recurrence.frequency
 * @param {'DAY','DATE'} props.order.recurrence.day
 * @param {'JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER'} props.order.recurrence.month
 * @param {'MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY'} props.order.recurrence.weekday
 * @param {Array<'MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY'>} props.order.recurrence.weekdays
 * @param {number} props.order.recurrence.date
 * @param {number} props.order.recurrence.hour
 * @param {number} props.order.recurrence.minute
 * @param {boolean} props.order.recurrence.forceAutoBill
 * @param {boolean} props.order.recurrence.autoBill
 * @param {string} props.order.recurrence.activated - timestamp
 * @param {string} props.order.recurrence.expires - timestamp
 * @param {number} props.order.recurrence.termQuantity
 * @param {'DAYS','WEEKS','MONTHS','YEARS'} props.order.recurrence.termUnits
 * @param {boolean} props.order.recurrence.allowAutoRenew
 * @param {boolean} props.order.recurrence.autoRenew
 * @param {number} props.order.recurrence.renewalPremium
 * @param {boolean} props.order.recurrence.excludeWeekends
 * @param {boolean} props.order.recurrence.excludeWeekdays
 * @param {boolean} props.order.recurrence.prepaid
 * @param {boolean} props.order.recurrence.lockPrice
 * @param {string} props.order.recurrence.nextDate
 * @param {string} props.form
 * @param {boolean} props.showRecurrenceModal
 * @param {function} props.setShowRecurrenceModal
 * @param {boolean} props.use24Hr
 * @param {string} props.referenceDate
 * @return {JSX.Element}
 * @constructor
 */
const RecurrenceModal = props => {
	// TODO: 24HR
	const [recurrence, setRecurrence] = useState(props.order.recurrence.recurrence || RECURRENCE.WEEKLY)
	const [frequency, setFrequency] = useState(props.order.recurrence.frequency || 1)
	const [month, setMonth] = useState(props.order.recurrence.month)
	const [weekday, setWeekday] = useState(props.order.recurrence.weekday || Object.keys(WEEKDAY)[new Date(Date.now()).getDay()])
	const [date, setDate] = useState(props.order.recurrence.date)
	const [stop, setStop] = useState(11)
	const [referenceDate, setReferenceDate] = useState(props.referenceDate ? new Date(props.order.dueDate) : new Date(Date.now()))
	const [nextDate, setNextDate] = useState(props.order.recurrence.nextDate ? new Date(props.order.recurrence.nextDate) : new Date(Date.now()+7*24*60*60*1000))
	const [time, setTime] = useState(new Time(props.order.recurrence.time))
	const [showOrdinals, setShowOrdinals] = useState(false)
	const [showDays, setShowDays] = useState(true)
	const [showMonths, setShowMonths] = useState(false)
	useEffect(() => {
		setRecurrence(props.order.recurrence.recurrence || RECURRENCE.WEEKLY)
	}, [props.order.recurrence.recurrence])
	useEffect(() => {
		setFrequency(props.order.recurrence.frequency || 1)
	}, [props.order.recurrence.frequency])
	useEffect(() => {
		setMonth(props.order.recurrence.month)
	}, [props.order.recurrence.month])
	useEffect(() => {
		setWeekday(props.order.recurrence.weekday || Object.keys(WEEKDAY)[new Date(Date.now()).getDay()])
	}, [props.order.recurrence.weekday])
	useEffect(() => {
		setDate(props.order.recurrence.date)
	}, [props.order.recurrence.date])
	useEffect(() => {
		try {
			props.referenceDate ? setReferenceDate(stringDateAndTimeToDate(props.referenceDate)) : setReferenceDate(new Date(Date.now()))
		} catch (e) {
			setReferenceDate(new Date(Date.now()))
			console.log(e)
		}
	}, [props.order.dueDate])
	useEffect(() => {
		try {
			setNextDate(new Date(props.order.recurrence.nextDate))
		} catch (e) {
			console.log(e)
		}
	}, [props.order.recurrence.nextDate])
	useEffect(() => {
		try {
			if (props.order.recurrence.hour) {
				setTime(new Time({hour: props.order.recurrence.hour, minute: props.order.recurrence.minute}))
			} else {
				const timeNow = new Date(Date.now())
				setTime(new Time({hour:timeNow.getHours(), minute:timeNow.getMinutes()}))
			}
		} catch (e) {
			console.log(e)
		}
	}, [props.order.recurrence.hour, props.order.recurrence.minute])
	useEffect(() => {
		switch (recurrence) {
			case RECURRENCE.DAILY:
				setStop(6)
				setShowOrdinals(false)
				setShowDays(false)
				setShowMonths(false)
				break
			case RECURRENCE.WEEKLY:
				setStop(6)
				setShowOrdinals(false)
				setShowDays(true)
				setShowMonths(false)
				break
			case RECURRENCE.MONTHLY:
				setStop(11)
				setShowOrdinals(true)
				setShowDays(true)
				setShowMonths(false)
				break
			case RECURRENCE.YEARLY:
				setStop(5)
				setShowOrdinals(true)
				setShowDays(true)
				setShowMonths(true)
				break
			case RECURRENCE.ONCE:
				props.setShowRecurrenceModal(false)
				break
		}
	}, [recurrence])
	useEffect(() => {
		try {
			const months = Object.keys(MONTH)
			const newReferenceDate = referenceDate ? new Date(referenceDate) : new Date(Date.now())
			console.log("newReferenceDate", newReferenceDate)
			if (recurrence === RECURRENCE.YEARLY) {
				newReferenceDate.setMonth(months.findIndex(m => m === month))
			}
			const deltaDate = getDateDelta(
				recurrence === RECURRENCE.YEARLY ? frequency : 0,
				recurrence === RECURRENCE.MONTHLY ? frequency : 0,
				recurrence === RECURRENCE.WEEKLY ? frequency * 7 :
					recurrence === RECURRENCE.DAILY ? frequency : 0,
				new Date(newReferenceDate))
			console.log("deltaDate", deltaDate)
			if (recurrence === RECURRENCE.DAILY) {
				setNextDate(deltaDate)
			// } else if (recurrence === RECURRENCE.WEEKLY) {
			// 	const targetDay = Object.keys(WEEKDAY).findIndex(d => d === weekday)
			// 	const deltaDay = deltaDate.getDay()
			// 	setNextDate(targetDay === deltaDay ? deltaDate : getDateDelta(0,0,deltaDay-targetDay, deltaDate))
			} else {
				const days = Object.keys(WEEKDAY)
				const firstDayOfMonth = new Date()
				firstDayOfMonth.setFullYear(deltaDate.getFullYear())
				firstDayOfMonth.setMonth(deltaDate.getMonth())
				firstDayOfMonth.setDate(1)
				console.log("firstDayOfMonth", firstDayOfMonth)
				const weekdayOfFirstDayOfMonth = firstDayOfMonth.getDay()
				const lastDayOfMonth = new Date()
				lastDayOfMonth.setMonth(deltaDate.getMonth() + 1)
				lastDayOfMonth.setFullYear(deltaDate.getFullYear())
				lastDayOfMonth.setDate(0)
				console.log("lastDayOfMonth", lastDayOfMonth)
				const dateOfLastDayOfMonth = lastDayOfMonth.getDate()
				console.log("dateOfLastDayOfMonth", dateOfLastDayOfMonth)
				const targetWeekday = days.findIndex(d => d === weekday)
				console.log("weekday", weekday)
				console.log("targetWeekday", targetWeekday)
				const weekdayDelta = targetWeekday - weekdayOfFirstDayOfMonth
				console.log("weekdayDelta", weekdayDelta)
				const firstMatchingWeekdayDate = weekdayDelta >= 0 ? weekdayDelta + 1 : weekdayDelta + 8
				console.log("firstMatchingWeekdayDate", firstMatchingWeekdayDate)
				const referenceWeek = recurrence === RECURRENCE.WEEKLY ? Math.round(deltaDate.getDate() / 7 + 0.49) : Number(date)
				const targetWeekdayDate = (referenceWeek === -1 ? 4 : referenceWeek - 1) * 7 + firstMatchingWeekdayDate
				console.log("date", date)
				console.log("referenceWeek", referenceWeek)
				console.log("targetWeekdayDate", targetWeekdayDate)
				targetWeekdayDate > dateOfLastDayOfMonth ? deltaDate.setDate(targetWeekdayDate - 7) : deltaDate.setDate(targetWeekdayDate)
				console.log("next", deltaDate)
				console.log("deltaDate.getTime()", deltaDate.getTime())
				console.log("newReferenceDate.getTime()", newReferenceDate.getTime())
				console.log("Math.round((deltaDate.getTime() - newReferenceDate.getTime())", Math.round((deltaDate.getTime() - newReferenceDate.getTime())))
				const daysBetweenReferenceAndDelta = Math.round((deltaDate.getTime() - newReferenceDate.getTime()) / (1000 * 3600 * 24))
				console.log("daysBetweenReferenceAndDelta", daysBetweenReferenceAndDelta)
				console.log("deltaDate", deltaDate)
				console.log("newReferenceDate", newReferenceDate)
				setNextDate(((recurrence === RECURRENCE.WEEKLY) && (daysBetweenReferenceAndDelta < frequency * 7)) ?
					getDateDelta(0, 0, 7, deltaDate) : deltaDate)
			}
		} catch (e) {
			console.log(e)
		}
	}, [recurrence, frequency, month, weekday, date, referenceDate])
	useEffect(() => {
		try {
			console.log("ID", `${props.order.id}-next-date-input`)
			console.log("nextDate", nextDate)
			console.log("document.getElementById(`${props.order.id}-next-date-input`)", document.getElementById(`${props.order.id}-next-date-input`))
			document.getElementById(`${props.order.id}-next-date-input`).valueAsDate = nextDate
		} catch (e) {
			console.log(e)
		}
	}, [props.order, nextDate])
	console.log("nextDate", nextDate)
	console.log("time", time)
	console.log("props.order.recurrence", props.order.recurrence)
	const className = "paralian-theme-level-1"
	const formId = "recurrence-modal"
	const onSubmit = async event => {
		try {
			event.preventDefault()
			const getRecurrence = () => {
				let _frequency = null, _recurrence = null, _date = null, _weekday = null
				let _next = null, _time = null, _month = null
				try {
					_frequency = document.getElementById(`${props.order.id}-recurrence-frequency-input`).getAttribute("data-a")
					console.log("_frequency", _frequency)
				} catch (e) {
					console.log("failed to get frequency value", e)
				}
				try {
					_recurrence = document.getElementById(`${props.order.id}-recurrence-recurrence-input`).getAttribute("data-a")
					console.log("_recurrence", _recurrence)
				} catch (e) {
					console.log("failed to get recurrence value", e)
				}
				try {
					_date = document.getElementById(`${props.order.id}-recurrence-date-input`).getAttribute("data-a")
					console.log("_date", _date)
				} catch (e) {
					console.log("failed to get date value", e)
				}
				try {
					_weekday = document.getElementById(`${props.order.id}-recurrence-weekday-input`).getAttribute("data-a")
					console.log("_weekday", _weekday)
				} catch (e) {
					console.log("failed to get weekday value", e)
				}
				try {
					_month = document.getElementById(`${props.order.id}-recurrence-month-input`).getAttribute("data-a")
					console.log("_month", _month)
				} catch (e) {
					console.log("failed to get month value", e)
				}
				try {
					_next = document.getElementById(`${props.order.id}-next-date-input`).value
					console.log("_next", _next)
				} catch (e) {
					console.log("failed to get next value", e)
				}
					console.log("time", time)
				return ({id: props.order.id, frequency: _frequency, recurrence: _recurrence, date: _date,
					weekday: _weekday, month: _month,	next: _next, time: time})
			}
			const recurrence = getRecurrence()
			const response = props.order.buttons.editRecurrence ? await editOrderRecurrence(recurrence) :
				await setOrderRecurrence(recurrence)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.refreshOrders()
				props.setShowRecurrenceModal(false)
				fadingMessage("enter-edit-recurrence-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
				if (props.onSubmit) {
					props.onSubmit(recurrence)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content =
		<div>
			<div style={{padding: "2vh", margin: "1vh 0vh", height: "6vh"}}>{translate(DICTIONARY.EVERY.X)}</div>
			<div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
				{/* 1-6 for Weeks, 1-12 for Months, 1-5 for Years */}
				<div style={{minWidth: "45%"}} onClick={() => {}}>
					<MobileNumberRangeDropdown start={1} stop={stop} step={1} id={`${props.order.id}-recurrence-frequency`}
						form={formId} showLabel={false} defaultValue={frequency} className={className}
						onChange={f => setFrequency(Number(f))}/>
				</div>
				{/* Weeks, Months, Years */}
				<div style={{marginLeft: "1vh", width: "100%"}} onClick={() => {}}>
					<MobileRecurrenceDropdown id={`${props.order.id}-recurrence-recurrence`} form={formId} showLabel={false}
						defaultValue={recurrence || RECURRENCE.WEEKLY} exclude={[RECURRENCE.DAILY, RECURRENCE.ONCE, RECURRENCE.YEARLY]}
						className={className} onChange={r => setRecurrence(r)}/>
				</div>
			</div>
			{/* ON | ON_THE */}
			{
				showDays || showOrdinals || showMonths ?
					<div style={{padding: "1vh"}}>
						{
							`- ${translate(!showOrdinals && showDays && !showMonths ?
								DICTIONARY.ON.X : DICTIONARY.ON_THE.X).toUpperCase()} -`
						}
					</div> : <></>
			}
			<div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
				{showOrdinals ?
					<div style={{minWidth: "45%"}} onClick={() => {
					}}>
						<MobileOrdinalsDropdown id={`${props.order.id}-recurrence-date`} form={formId} defaultValue={date}
							className={className} showLabel={false} onChange={d => setDate(Number(d))}/>
					</div> : <></>}
				{showDays ?
					<div style={{marginLeft: !showOrdinals && showDays ? "0vh" : "1vh", width: "100%"}} onClick={() => {
					}}>
						<MobileDaysDropdown id={`${props.order.id}-recurrence-weekday`} form={formId} defaultValue={weekday}
							className={className} showLabel={false} plural={false}
							onChange={w => setWeekday(w)}/>
					</div> : <></>}
			</div>
			{showMonths ? <>
				<div style={{padding: "1vh"}}>
					{`- ${translate(DICTIONARY.OF.X).toUpperCase()} -`}
				</div>
				<div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
					<div style={{width: "100%"}} onClick={() => {
					}}>
						<MobileMonthDropdown id={`${props.order.id}-recurrence-month`} form={formId} showLabel={false}
							defaultValue={month} className={className} onChange={m => setMonth(m)}/>
					</div>
				</div>
			</> : <></>}
			<div style={{padding: "1vh"}}>
				{`- ${translate(DICTIONARY.AT.X).toUpperCase()} -`}
			</div>
			<div style={{paddingTop: "1vh", paddingBottom: "1vh"}}>
				<MobileTimeField id={`${props.order.id}-time`} form={formId} className={className} time={time}
					style={{fontSize: "3vh", height: "6vh"}} use24Hr={props.use24Hr || getTimeFormat() !== "xm"} setTime={setTime}/>
			</div>
			<fieldset style={{margin: "1vh 0vh"}}>
				<legend style={{padding: "0vh 1vh", fontSize: "2.5vh"}}>{
					<>{translate(DICTIONARY.NEXT_DUE_DATE.X)}
						<MobileFontAwesome id={"due-date"} icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.NEXT_DUE_DATE.X)}
							style={{paddingLeft: "1vh"}} onClick={() => newMessage("nextDueDate",
							translate(DICTIONARY.NEXT_DUE_DATE.X), translate(DICTIONARY.DUE_DATE_MESSAGE.X),
							"w3-pale-blue", ICONS.INFO_CIRCLE)}/>
					</>}
				</legend>
				<div style={{padding: "1vh"}}>
					<MobileInputDate id={`${props.order.id}-next-date`} label={translate(DICTIONARY.DATE.X)} name={"nextDate"}
						showLabel={false} className={"paralian-theme-level-2"} onChange={d => {
							// console.log("d", d)
							const split = d.split("-")
							// console.log("split", split)
							const year = Number(split[0])
							// console.log("year", year)
							const month = Number(split[1])
							// console.log("month", month)
							const date = Number(split[2])
							// console.log("date", date)
							const dateObject = new Date()
							dateObject.setFullYear(year)
							dateObject.setMonth(month-1)
							dateObject.setDate(date)
							// console.log("dateObject", dateObject)
							setNextDate(dateObject)
						}}
						autoComplete={false} required={true} defaultValue={dateToYYYYMMDD(new Date(nextDate))}
						min={dateToYYYYMMDD(getDateDelta(0, 0, recurrence === "DAYS" ? frequency : 7))}
						max={dateToYYYYMMDD(getDateDelta(6, 0, 0))} autoFocus={false} form={formId}/>
				</div>
			</fieldset>
			{/*TODO: notify user if selected date doesn't match previously selected criteria*/}
		</div>
	return props.showRecurrenceModal ?
		<Modal title={translate(DICTIONARY.RECURRENCE.X)} padding={"0vh"} id={`modal-${props.order.id}-recurrence`}
			icon={ICONS.REPEAT} onClick={() => props.setShowRecurrenceModal(false)}
			body={<MobileInputForm id={formId} content={content} onSubmit={onSubmit}/>}/> : <></>
}

export default RecurrenceModal