import {store} from "../../../index"
import {actions} from "../../constants/client/reduxData"
import {DICTIONARY, ICONS, MEASUREMENT_UNITS, MONTH, PAGE_STATES, PAGES, FIREBASE_CONFIG} from "../../constants/client/constants"
import {ORDER_TYPE,	SERVER_RESPONSE_STATE, UNIT_SYSTEM,	VERIFICATION_TYPE} from "../../constants/shared/enumerators"
import {
	logError,
	loginRefresh,
	sendVerificationCodeRequest,
	setBirthDate,
	setPreferredLanguage,
	setPreferredTimeFormat,
	setPreferredUnits,
	setPushSubscription,
	uploadImage, vesselOwnershipConfirm
} from "./serverFunctions"
import setLoggedIn from "./mode/setLoggedIn"
import respondToPasswordCheck from "../../../mobile/login/current/respondToPasswordCheck"
import getLoggedIn from "./mode/getLoggedIn"
/* TODO: switch to modular mode on firebase */
// import firebase from "firebase/compat/app"
// import 'firebase/compat/messaging'
import { initializeApp, getApps, getApp } from "firebase/app"
import {getMessaging, getToken} from "firebase/messaging"
import convert from "convert-units"
import Mode from "../../classes/client/Mode"

try {
	const firebase = getApps().length === 0 ? initializeApp(FIREBASE_CONFIG) : getApp()
	console.log("firebase", firebase)
} catch (e) {
	console.log(e)
}

window.textToIcon = text => {
	try {
		switch (text ? text.toLowerCase() : "") {
			case "new":
				return {icon: ICONS.STAR, color: "inherit", text: translate(DICTIONARY.NEW_REQUEST.X)}
			case "fuel":
				return {icon: ICONS.GAS_PUMP, color: "inherit", text: translate(DICTIONARY.FUEL.X)}
			case "water":
				return {icon: ICONS.FAUCET, color: "inherit", text: translate(DICTIONARY.WATER.X)}
			case "wifi":
				return {icon: ICONS.WIFI, color: "inherit", text: translate(DICTIONARY.WIFI.X)}
			case "ethernet":
				return {icon: ICONS.NETWORK_WIRED, color: "inherit", text: translate(DICTIONARY.ETHERNET.X)}
			case "cable-tv":
				return {icon: ICONS.TV_RETRO, color: "inherit", text: translate(DICTIONARY.CABLE_TV.X)}
			case "shore power":
				return {icon: ICONS.BOLT, color: "inherit", text: translate(DICTIONARY.SHORE_POWER.X)}
			case "electricity":
				return {icon: ICONS.OUTLET, color: "inherit", text: translate(DICTIONARY.ELECTRICITY.X)}
			case "active":
				return {icon: ICONS.CHECK, color: "green", text: translate(DICTIONARY.ACTIVE.X)}
			case "completed":
				return {icon: ICONS.CHECK_CIRCLE, color: "green", text: translate(DICTIONARY.COMPLETED.X)}
			case "confirm":
				return {icon: ICONS.EXCLAMATION_CIRCLE, color: "orange", text: translate(DICTIONARY.AWAITING_YOUR_RESPONSE.X)}
			case "incomplete":
				return {icon: ICONS.STAR_HALF_ALT, color: "orange", text: translate(DICTIONARY.INCOMPLETE.X)}
			case "aborted":
				return {icon: ICONS.TIMES_CIRCLE, color: "red", text: translate(DICTIONARY.CANCELLED.X)}
			case "adjust":
				return {icon: ICONS.EXCLAMATION_CIRCLE, color: "orange", text: translate(DICTIONARY.AWAITING_YOUR_RESPONSE.X)}
			case "adjust-provider":
				return {icon: ICONS.EXCLAMATION_CIRCLE, color: "orange", text: translate(DICTIONARY.AWAITING_CUSTOMER_RESPONSE.X)}
			case "entered":
				return {icon: ICONS.SPINNER, color: "yellow", text: translate(DICTIONARY.AWAITING_CONFIRMATION.X)}
			case "accepted":
				return {icon: ICONS.CLIPBOARD_LIST_CHECK, color: "green", text: translate(DICTIONARY.CONFIRMED.X)}
			case "denied":
				return {icon: ICONS.TIMES_CIRCLE, color: "red", text: translate(DICTIONARY.DENIED.X)}
			case "scheduled":
				return {icon: ICONS.HOURGLASS_HALF, color: "inherit", text: translate(DICTIONARY.SCHEDULED.X)}
			case "started":
				return {icon: ICONS.USER_HARD_HAT, color: "green", text: translate(DICTIONARY.IN_PROGRESS.X)}
			case "qr code":
				return {icon: ICONS.QR_CODE, color: "inherit", text: translate(DICTIONARY.QR_CODE.X)}
			case "orders":
				return {icon: ICONS.CALENDAR, color: "inherit", text: translate(DICTIONARY.ORDERS.X)}
			case "requests":
				return {icon: ICONS.TRUCK, color: "inherit", text: translate(DICTIONARY.REQUESTS.X)}
			case "notifications":
				return {icon: ICONS.BELL, color: "inherit", text: translate(DICTIONARY.NOTIFICATIONS.X)}
			case "tools":
				return {icon: ICONS.TOOLS, color: "inherit", text: translate(DICTIONARY.TOOLS.X)}
			case "vessel facilities":
				return {icon: ICONS.PARKING, color: "inherit", text: translate(DICTIONARY.VESSEL_STORAGE.X)}
			case "equipment rental":
				return {icon: ICONS.SNOWBOARDING, color: "inherit", text: translate(DICTIONARY.EQUIPMENT_RENTAL.X)}
			case ORDER_TYPE.VESSEL_STORAGE_UNIT_RESERVATION:
				return {icon: ICONS.SHIP, color: "inherit", text: translate(DICTIONARY.VESSEL_STORAGE_UNIT_RESERVATION.X)}
			case ORDER_TYPE.VESSEL_TRANSPORT:
				return {icon: ICONS.FORKLIFT, color: "inherit", text: translate(DICTIONARY.VESSEL_TRANSPORT.X)}
			case "vessel fueling":
				return {icon: ICONS.GAS_PUMP, color: "inherit", text: translate(DICTIONARY.VESSEL_FUELING.X)}
			case "vessel cleaning":
				return {icon: ICONS.SOAP, color: "inherit", text: translate(DICTIONARY.VESSEL_CLEANING.X)}
			case "shuttle service":
				return {icon: ICONS.SHUTTLE_VAN, color: "inherit", text: translate(DICTIONARY.SHUTTLE_SERVICE.X)}
			case "pneumatic systems maintenance and repair":
				return {icon: ICONS.PUMP, color: "inherit", text: translate(DICTIONARY.PNEUMATIC_SYSTEMS.X)}
			default:
				return {icon: ICONS.QUESTION_CIRCLE, color: "inherit", text: ""}
		}
	} catch (e) {
		console.log(e)
		return {icon: ICONS.QUESTION_CIRCLE, color: "inherit", text: ""}
	}
}

/**
 *
 * @param {Object} data
 * @param {string} data.title
 * @param {string} data.text
 * @param {string} data.url
 * @return {Promise<void>}
 */
export const share = async data => {
	try {
		await window.navigator.share(data)
		console.log("shared")
	} catch (e) {
		console.log(e)
	}
}
export const hideByElementId = id => {
	try {
		const x = document.getElementById(id)
		if (x.className.indexOf("w3-show") !== -1) {
			x.className = x.className.replace("w3-show", "")
		}
	} catch (e) {
		console.log(e)
	}
}

export const showByElementId = id => {
	try {
		const x = document.getElementById(id)
		if (x.className.indexOf("w3-show") === -1) {
			x.className += " w3-show"
		}
	} catch (e) {
		console.log(e)
	}
}

export const newMessage = (id, title, text, color = "w3-pale-red", icon = ICONS.BAN, buttons = null) => {
	try {
		if (document.getElementById("messageContainer").style.display === "none") {
			showByElementId("messageContainer")
		}
		store.dispatch({type: actions.ADD_MESSAGE,
			values: {id: id, color: color, title: ` ${title}`, text: text, icon: icon, buttons: buttons}})
	} catch (e) {
		console.log(e)
	}
}

export const fadeOut = (id, rate = 20) => {
	try {
		const element = document.getElementById(id)
		const interval = setInterval(() => {
			try {
				if (!element.style.opacity) {
					element.style.opacity = "1"
				}
				if (element.style.opacity > 0) {
					element.style.opacity = `${element.style.opacity - 0.01}`
				} else {
					clearInterval(interval)
				}
			} catch (e) {
				clearInterval(interval)
				console.log(e)
			}
		}, rate)
	} catch (e) {
		console.log(e)
	}
}

export const deleteMessage = (id, fade = true) => {
	try {
		if (fade) {
			const timeout = 2000
			fadeOut(id, timeout/100)
			setTimeout(() => store.dispatch({type: actions.DELETE_MESSAGE,	values: {id: id}}), timeout)
		} else {
			store.dispatch({type: actions.DELETE_MESSAGE,	values: {id: id}})
		}
	} catch (e) {
		console.log(e)
	}
}

export const fadingMessage = (id, title, text, color = "w3-pale-green", icon = ICONS.CHECK, time = 3000) => {
	try {
		newMessage(id, title, text, color, icon)
		setTimeout(() => deleteMessage(id), time)
	} catch (e) {
		console.log(e)
	}
}

/**
 *
 * @param message {Object}
 * @param message.title {string}
 * @param message.text {string}
 * @param message.type {string}
 */
export const serverMessage = message => {
	try {
		let color, icon
		switch (message.type) {
			case "success":
				icon = ICONS.CHECK
				color = "w3-pale-green"
				break
			case "info":
				icon = ICONS.INFO
				color = "w3-pale-blue"
				break
			case "warning":
				icon = ICONS.EXCLAMATION
				color = "w3-pale-yellow"
				break
			case "error":
				icon = ICONS.BAN
				color = "w3-pale-red"
				break
			default:
				return null
		}
		if (document.getElementById("messageContainer").style.display === "none") {
			showByElementId("messageContainer")
		}
		store.dispatch({type: actions.ADD_MESSAGE,
			values: {id: `${message.type}-${message.title}`, color: color, title: message.title, text: message.text, icon: icon}})
	} catch (e) {
		console.log(e)
	}
}

export const toggleVisibility = (passwordElementId, visibilityButtonId) => {
	try {
		let x = document.getElementById(passwordElementId)
		let y = document.getElementById(visibilityButtonId)
		if (x.type === "password") {
			x.type = "text";
			y.innerHTML = "visibility"
		} else {
			x.type = "password";
			y.innerHTML = "visibility_off"
		}
	} catch (e) {
		console.log(e)
	}
}

export const togglePasswordVisibility = (passwordInputId, visibilityButtonId, setIcon) => {
	try {
		let x = document.getElementById(passwordInputId)
		if (x.type === "password") {
			x.type = "text"
			setIcon(ICONS.EYE)
		} else {
			x.type = "password"
			setIcon(ICONS.EYE_SLASH)
		}
	} catch (e) {
		console.log(e)
	}
}

export const toggleLoader = () => {
	try {
		const x = document.getElementById("loader")
		if (x.className.indexOf("w3-show") === -1) {
			x.className += " w3-show"
		} else {
			x.className = x.className.replace(" w3-show", "")
		}
	} catch (e) {
		console.log(e)
	}
}

export const showLoader = () => {
	try {
		const x = document.getElementById("loader")
		if (x.className.indexOf("w3-show") === -1) {
			x.className += " w3-show"
		}
	} catch (e) {
		console.log(e)
	}
}

export const hideLoader = () => {
	try {
		const x = document.getElementById("loader")
		x.className = x.className.replace(" w3-show", "")
	} catch (e) {
		console.log(e)
	}
}

export const toggleClassesByElementId = (elementId, classOne, classTwo) => {
	try {
		const x = document.getElementById(elementId)
		if (x.className.indexOf(classOne) !== -1) {
			x.className = x.className.replace(classOne, classTwo)
		} else {
			x.className = x.className.replace(classTwo, classOne)
		}
	} catch (e) {
		console.log(e)
	}
}

export const addClassByElementId = (className, elementId) => {
	try {
		const x = document.getElementById(elementId);
		if (x.className.indexOf(className) === -1) {
			x.className += ` ${className}`;
		}
	} catch (e) {
		console.log(e)
	}
}

export const removeClassByElementId = (className, elementId) => {
	try {
		const x = document.getElementById(elementId)
		if (x.className.indexOf(className) !== -1) {
			x.className = x.className.replace(className, "")
		}
	} catch (e) {
		console.log(e)
	}
}

export const toggleOverflow = elementId => {
	try {
		const x = document.getElementById(elementId);
		if (x.className.indexOf("paralian-overflow-nowrap-ellipsis") === -1 && x.className.indexOf("paralian-overflow-scroll") !== -1 ) {
			x.scrollTo(0,0);
			x.className = x.className.replace("paralian-overflow-scroll", "paralian-overflow-nowrap-ellipsis", );
		} else {
			x.className = x.className.replace("paralian-overflow-nowrap-ellipsis", "paralian-overflow-scroll");
		}
	} catch (e) {
		console.log(e)
	}
}

export const toggleByElementId = elementId => {
	try {
		const x = document.getElementById(elementId)
		if (x.className.indexOf("w3-show") === -1) {
			x.className += " w3-show"
		} else {
			x.className = x.className.replace("w3-show", "")
		}
	} catch (e) {
		console.log(e)
	}
}

export const toggleArrowsByElementId = elementId => {
	try {
		const x = document.getElementById(elementId)
		if (x.className.indexOf(ICONS.ANGLE_DOUBLE_DOWN) !== -1) {
			x.className = x.className.replace(ICONS.ANGLE_DOUBLE_DOWN, ICONS.ANGLE_DOUBLE_UP)
		} else {
			x.className = x.className.replace(ICONS.ANGLE_DOUBLE_UP, ICONS.ANGLE_DOUBLE_DOWN)
		}
	} catch (e) {
		console.log(e)
	}
}

export const toggleColorByElementId = elementId => {
	try {
		const x = document.getElementById(elementId)
		if (x.className.indexOf("paralian-theme-level-1-flipped-blue") === -1) {
			x.className += " paralian-theme-level-1-flipped-blue"
		} else {
			x.className = x.className.replace("paralian-theme-level-1-flipped-blue", "")
		}
	} catch (e) {
		console.log(e)
	}
}

export const goToDeactivatedColorByElementId = elementId => {
	try {
		const x = document.getElementById(elementId);
		if (x.className.indexOf("paralian-theme-level-1-flipped-blue") === -1) {
			x.className += " paralian-theme-level-1-flipped-blue";
		}
	} catch (e) {
		console.log(e)
	}
}

export const meanZeroHeightOneBellCurve = (x, standardDeviation) => {
	try {
		const rightNumerator = -Math.pow(x,2)
		const rightDenominator = 2*Math.pow(standardDeviation,2)
		return Math.exp(rightNumerator/rightDenominator)
	} catch (e) {
		console.log(e)
		return 1
	}
}

export const spinElement = elementId => {
	try {
		const x = document.getElementById(elementId)
		if (x.className.indexOf("w3-spin") === -1) {
			x.className += " w3-spin"
		} else {
			x.className = x.className.replace(" w3-spin", "")
		}
	} catch (e) {
		console.log(e)
	}
}

export const moveFrame = (currentFrame, frameDelta, pathPrefix, pathSuffix = '', minFrame = 1, maxFrame = 20) => {
	try {
		const baseFrame = 1
		const deltaMinFrame = minFrame - baseFrame
		const newCurrentFrame = currentFrame - deltaMinFrame
		const newMaxFrame = maxFrame - deltaMinFrame
		const newFrame = ((newCurrentFrame + frameDelta) % newMaxFrame || newMaxFrame) + deltaMinFrame
		const newPath = pathPrefix + newFrame + pathSuffix
		return {frame: newFrame, path: newPath}
	} catch (e) {
		console.log(e)
		return {frame: 1, path: ""}
	}
}

export const connectionCheck = () => {
	try {
		if (!navigator.onLine) {
			newMessage("offline", translate(DICTIONARY.OFFLINE.X), translate(DICTIONARY.DEVICE_OFFLINE.X),
				"w3-pale-red", ICONS.EXCLAMATION_CIRCLE)
		}
		return navigator.onLine
	} catch (e) {
		console.log(e)
		return false
	}
}

export const mysqlPolyToGMapsPoly = poly => {
	try {
		const googleMapsPoly = []
		for (let i = 0; i < poly.length; i++) {
			googleMapsPoly[i] = {lng: poly[i].x, lat: poly[i].y}
		}
		return googleMapsPoly
	} catch (e) {
		console.log(e)
		return []
	}
}

export const dateToUTCdmyText = date => {
	try {
		if (isNaN(date.getTime())) {
			return 'N/A'
		}
		return `${date.getUTCDate()} ${translate(MONTH[date.getUTCMonth()])} ${date.getUTCFullYear()}`
	} catch (e) {
		console.log(e)
		return 'N/A'
	}
}

export const valOrNA = value => {
	try {
		return (value ? `${value}` : 'N/A')
	} catch (e) {
		return 'N/A'
	}
}

/**
 *
 * @param {string} stringDateAndTime
 * @return {Date}
 */
export const stringDateAndTimeToDate = stringDateAndTime => {
	try {
		const split = stringDateAndTime.replace(/-/g, ":").split(":")
		return new Date(split[0], Number(split[1]) - 1, split[2], split[3], split[4])
	} catch (e) {
		console.log(e)
	}
}

/**
 *
 * @param {number} [years]
 * @param {number} [months]
 * @param {number} [days]
 * @param {Date} [date]
 * @return {Date}
 */
export const getDateDelta = (years = 0, months = 0, days = 0, date = null) => {
	try {
		const today = date ? date : new Date()
		today.setFullYear(today.getFullYear() + years)
		today.setMonth(today.getMonth() + months)
		today.setDate(today.getDate() + days)
		return today
	} catch (e) {
		console.log(e)
		return new Date()
	}
}

/**
 *
 * @param {Date} dateObject
 * @return {string}
 */
export const dateToYYYYMMDD = dateObject => {
	try {
		let date = dateObject.getDate()
		if (date < 10) {date = "0" + date}
		let month = dateObject.getMonth() + 1
		if (month < 10) {month = "0" + month}
		const year = dateObject.getFullYear()
		return `${year}-${month}-${date}`
	} catch (e) {
		console.log(e)
		return ""
	}
}

export const getDocIds = docsArray => {
	try {
		let newArray = []
		for (let i = 0; i < docsArray.length; i++) {
			let newObject = {}
			newObject["id"] = docsArray[i]["id"]
			newArray[i] = newObject
		}
		return newArray
	} catch (e) {
		console.log(e)
		return []
	}
}

export const handleLoginPrivate = async event => {
	try {
		event.preventDefault()
		const password = document.getElementById("password-input").value
		const response = await loginRefresh(password)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			store.dispatch({type: actions.HIDE_LOGIN_CARD, values: {}})
		} else {
			respondToPasswordCheck(response.payload.status)
		}
	} catch (e) {
		console.log(e)
	}
}

export const getCookie = cname => {
	try {
		const name = cname + "="
		const  ca = document.cookie.split(';')
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === ' ') {
				c = c.substring(1)
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length)
			}
		}
		return ""
	} catch (e) {
		console.log(e)
		return ""
	}
}

/**
 *
 * @param {string} _canvas_element_id
 * @param {string} _file_type - mimetype
 * @param {string} _image_category - IMAGE_CATEGORY
 * @param {number} _id
 * @returns {Promise<void>}
 */
export const handleFileUpload = async (_canvas_element_id, _file_type, _image_category, _id = -1) => {
	return new Promise(resolve => {
		const canvas = document.getElementById(_canvas_element_id)
		canvas.toBlob(async _image => {
			if (_image.size > 2.5*1024*1024) {
				console.log("image too large")
				resolve({status: SERVER_RESPONSE_STATE.BAD})
				return
			}
			const response = await uploadImage(_image, _image_category, _id)
			// console.log(response)
			resolve(response)
		}, _file_type, 0.5)
	})
}

export const handleNewUserSubmit = async event => {
	try {
		event.preventDefault()
		const currentState = store.getState()
		const levelOne = currentState.window.current.levelOne
		const levelFour = currentState.window.current.levelFour
		switch (levelFour) {
			case PAGES.COMMON.LOGIN.NEW_USER.DATE_OF_BIRTH.X:
				const dateOfBirth = document.getElementById("dateOfBirth").value
				const response = await setBirthDate(dateOfBirth)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					store.dispatch({type: actions.SET_CURRENT_WINDOW,
						values: {window: PAGE_STATES[levelOne].LOGIN.NEW_USER.PROFILE_PICTURE.X}})
				}
				break
			case PAGES.COMMON.LOGIN.NEW_USER.PROFILE_PICTURE.X:
				try {
					await handleFileUpload("canvas", "image/png")
					// confirm address if available from invite, skip address if not
					if (currentState.form.invitation) {
						const account = await getFromStorage("accounts")[0]
						if (account.addresses) {
							store.dispatch({type: actions.SET_CURRENT_WINDOW,
								values: {window: PAGE_STATES[levelOne].LOGIN.NEW_USER.ADDRESS.X}})
						}
					} else {
						store.dispatch({type: actions.SET_CURRENT_WINDOW,
							values: {window: PAGE_STATES[levelOne].MAIN.X}})
					}
				} catch (e) {
					console.log(e)
				}
				break
			case PAGES.COMMON.LOGIN.NEW_USER.ADDRESS.X:
				setLoggedIn(true)
				store.dispatch({type: actions.SET_CURRENT_WINDOW,
					values: {window: PAGE_STATES[levelOne].MAIN.X}})
				break
			default:
				break
		}
	} catch (e) {
		console.log(e)
	}
}

export const checkVerificationCode = async (code, type, isOrganization = false) => {
	// console.log(code, type, isOrganization)
	try {
		const mode = new Mode(getFromStorage("mode"))
		const path_ = type === VERIFICATION_TYPE.PASSWORD_RESET ? "login" :
			(mode.loggedIn || isOrganization) ? "private" :
				type === VERIFICATION_TYPE.NEW_USER_INVITATION ? "public" : "limited"
		const response = type === VERIFICATION_TYPE.VESSEL_OWNERSHIP_CONFIRMATION ?
			await vesselOwnershipConfirm({code: code}) :
			await sendVerificationCodeRequest(code, type, path_, isOrganization)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			return response
		} else {
			switch (response.payload.status) {
				case "no results":
					newMessage(code, translate(DICTIONARY.INVALID_ENTRY.X), translate(DICTIONARY.INVALID_ENTRY_BODY.X),
						"w3-pale-red", ICONS.EXCLAMATION_TRIANGLE)
					break
				case "no match":
					newMessage(code, translate(DICTIONARY.INVALID_ENTRY.X), translate(DICTIONARY.INVALID_ENTRY_BODY.X),
						"w3-pale-red", ICONS.EXCLAMATION_TRIANGLE)
					break
				case "no matches":
					newMessage(code, translate(DICTIONARY.INVALID_ENTRY.X), translate(DICTIONARY.INVALID_ENTRY_BODY.X),
						"w3-pale-red", ICONS.EXCLAMATION_TRIANGLE)
					break
				case "wrong code":
					newMessage(code, translate(DICTIONARY.INVALID_ENTRY.X), translate(DICTIONARY.INVALID_ENTRY_BODY.X),
						"w3-pale-red", ICONS.EXCLAMATION_TRIANGLE)
					break
				case "code expired":
					newMessage(code, translate(DICTIONARY.CODE_EXPIRED.X), translate(DICTIONARY.CODE_EXPIRED_BODY.X),
						"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
					break
				default:
					newMessage(code, translate(DICTIONARY.ERROR.X), translate(DICTIONARY.CODE_ERROR_BODY.X),
						"w3-pale-red", ICONS.BAN)
			}
			return false
		}
	} catch (e) {
		console.log(e)
	}
}

export const activateStatusLoaderById = elementId => {
	removeClassByElementId(`${ICONS.CHECK} w3-animate-opacity`, elementId)
	removeClassByElementId(`${ICONS.BAN} w3-animate-opacity`, elementId)
	addClassByElementId(`${ICONS.SPINNER} w3-spin`, elementId)
}

export const replaceStatusLoaderById = (elementId, status) => {
	removeClassByElementId(` w3-spin`, elementId)
	if (status === SERVER_RESPONSE_STATE.SUCCESS) {
		addClassByElementId(` w3-animate-opacity`, elementId)
	} else {
		addClassByElementId(` w3-animate-opacity`, elementId)
	}
}

export const checkNewContactInfoCodesGood = () => {
	try {
		const levelOne = store.getState().window.current.levelOne
		const primaryEmail = document.getElementById("primaryEmailCodeIcon").className.indexOf(ICONS.CHECK)
		const backupEmail = document.getElementById("backupEmailCodeIcon").className.indexOf(ICONS.CHECK)
		const primaryPhone = document.getElementById("primaryPhoneCodeIcon").className.indexOf(ICONS.CHECK)
		if (primaryEmail !== -1 && backupEmail !== -1 && primaryPhone !== -1) {
			store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: PAGE_STATES[levelOne].LOGIN.NEW_USER.DATE_OF_BIRTH.X}})
		}
	} catch (e) {
		console.log(e)
	}
}
/**
 *
 * @param {string} screenElementId
 * @param {string} imageElementId
 * @param {string} canvasElementId
 */
export const handleCanvas = (screenElementId, imageElementId, canvasElementId) => {
	try {
		const screen = document.getElementById(screenElementId)
		const image = document.getElementById(imageElementId)
		const canvas = document.getElementById(canvasElementId)
		const context = canvas.getContext("2d")
		const multiplier = 300/image.width
		const ratio = image.width/image.height
		let currWidth = 300, currHeight = image.height*multiplier
		let startXa, startYa, distAB = 1, diff = 0
		let imageStartX = 0, imageStartY = 0, newX = 0, newY = 0, lastDist = 0
		let dragging = false
		let zooming = false
		screen.addEventListener("touchstart", evt => {
			startXa = evt.touches[0].clientX
			startYa = evt.touches[0].clientY
			if (evt.touches[1]) {
				zooming = true
				dragging = false
			} else {
				dragging = true
				zooming = false
			}
		}, false)
		screen.addEventListener("touchmove", evt => {
			if (zooming) {
				// console.log("more than one touch");
				distAB = Math.sqrt(Math.pow(evt.touches[0].clientX-evt.touches[1].clientX,2) +
					Math.pow(evt.touches[0].clientY-evt.touches[1].clientY,2))
				if (lastDist > distAB) {
					diff = -10
				} else {
					diff = 10
				}
				if (currWidth+diff > 100 && currHeight+diff > 100*ratio) {
					newX = imageStartX - diff/2
					newY = imageStartY - (diff/ratio)/2
					imageStartX = newX
					imageStartY = newY
					currWidth = currWidth+diff
					currHeight = currHeight+diff/ratio
				}
				lastDist = distAB
			} else if (dragging) {
				newX = imageStartX + evt.touches[0].clientX - startXa
				newY = imageStartY + evt.touches[0].clientY - startYa
				// console.log(["newX", newX, "newY", newY, "currWidth", currWidth, "currHeight", currHeight])
			}
			// console.log(["newX", newX, "newY", newY, "currWidth", currWidth, "currHeight", currHeight])
			context.clearRect(0,0,canvas.width, canvas.height)
			context.drawImage(image, newX, newY, currWidth,currHeight)
			// console.log(["newX", newX, "newY", newY, "currWidth", currWidth, "currHeight", currHeight])
		}, false)
		screen.addEventListener("touchend", () => {
			imageStartX = newX
			imageStartY = newY
			lastDist = 0
			dragging = false
			zooming = false
		}, false)
		screen.addEventListener("touchcancel", () => {
			dragging = false
			zooming = false
		}, false)
		context.drawImage(image, 0, 0, currWidth,currHeight)
	} catch (e) {
		console.log(e)
	}
}
/**
 *
 * @param {string} canvasElementId
 */
export const clearCanvas = canvasElementId => {
	try {
		const canvas = document.getElementById(canvasElementId)
		const context = canvas.getContext("2d")
		// Store the current transformation matrix
		context.save()
		// Use the identity matrix while clearing the canvas
		context.setTransform(1, 0, 0, 1, 0, 0)
		context.clearRect(0, 0, canvas.width, canvas.height)
		// Restore the transform
		context.restore()
	} catch (e) {
		console.log(e)
	}
}

export const parseListForDropdown = array => {
	try {
		return array.map(v => {
			return {value: v.id, label: v.name}
		})
	} catch (e) {
		console.log(e)
		return []
	}
}

export const parseListForDatalist = array => {
	try {
		return array.map(v => {
			return {value: v.name, label: v.name, code: v.id}
		})
	} catch (e) {
		console.log(e)
		return []
	}
}

export const parseCountriesDb = list => {
	try {
		const translator = new Intl.DisplayNames(undefined, { type: 'region' })
		return list.map(v => {
			return {
				label: translator.of(v.alpha2Code) || v.name,
				value: v.ISO3166Code,
				parentCountry: v.parentCountry,
				countryCode: v.alpha2Code,
				phoneCountryCode: v.phoneCountryCode
			}
		})
	} catch (e) {
		console.log(e)
		return []
	}
}

export const parseEnginesBrandsDb = list => {
	try {
		return list.map(v => {
			return {
				label: v.brand,
				value: v.brand,
				code: v.brand
			}
		})
	} catch (e) {
		console.log(e)
		return []
	}
}

export const parseMarinasList = list => {
	try {
		return list.map(v => {
			return {
				label: v.name,
				code: v.id,
				value: v.name
			}
		})
	} catch (e) {
		console.log(e)
		return []
	}
}

export const parseCountryDbForPhoneCodes = list => {
	try {
		return list.map(v => {
			return {
				label: `+ ${v.phoneCountryCode}`,
				value: v.ISO3166Code
			}
		})
	} catch (e) {
		console.log(e)
		return []
	}
}

export const parseFirstOrderAdminDivDb = list => {
	try {
		return list.map(v => {
			return {
				label: v.name,
				value: v.ISO3166_2,
				countryCode: v.country_code
			}
		})
	} catch (e) {
		console.log(e)
		return null
	}
}

export const parseCurrencyDb = list => {
	try {
		return list.map(v => {
			return {
				label: `${hexCharToString(v.htmlHexCode)} ${v.name}`,
				value: v.ISO4217Code
			}
		})
	} catch (e) {
		console.log(e)
		return []
	}
}

const hexCharToString = encoded => {
	try {
		const REG_HEX = /&#x([a-fA-F0-9]+);/g
		return encoded.replace(REG_HEX, (match, group1) => {
			const num = parseInt(group1, 16) //=> 39
			return String.fromCharCode(num)}
		)
	} catch (e) {
		console.log(e)
		return ""
	}
}

/**
 *
 * @param {string} unitSystem - SI or US, unit system to which to convert
 * @param {number} value
 * @param {string} unit - unit from which to convert
 * @param {number} displayDigits - decimals to display
 * @param {boolean} includeUnit
 * @param {boolean} localize
 * @return {string}
 */
export const convertToPreferredUnits = (value, unit, unitSystem = "SI", displayDigits = 0, includeUnit = true, localize = true) => {
	try {
		if (value === null) {
			return "#" + (includeUnit ? " " + unit : "")
		}
		const convertPower = () => ((unitSystem === "SI" && unit === "kW") || (unitSystem !== "SI" && unit === "hp")) ?
			value :	(unitSystem === "SI" && unit === "hp") ? value*0.7457 : value*1.34102
		let newUnit = (unit === "hp" || unit === "kW") ? unitSystem === "SI" ? "kW" : "hp" : ""
		if (MEASUREMENT_UNITS.distance.some(element => element === unit)) {
			if (unit === "km" || unit === "mi" || unit === "nm") {
				unitSystem === "SI" ? newUnit = "km" : unitSystem === "Non-SI" ? newUnit = "nm" : newUnit = "mi"
			}
			if (unit === "m" || unit === "ft") {
				unitSystem === "SI" ? newUnit = "m" : newUnit = "ft"
			}
			if (newUnit === "ft") {
				let feet = convert(value).from(unit).to(newUnit)
				let inches = Math.round((feet*12)%12)
				if (Math.round((feet*12)%12) === 12) {feet = Math.floor(feet) + 1; inches = 0} else {feet = Math.floor(feet)}
				return (`${feet}' ${inches}"`)
			}
		} else if (MEASUREMENT_UNITS.mass.some(element => element === unit)) {
			unitSystem === "SI" ? newUnit = "kg" : newUnit = "lb"
		} else if (MEASUREMENT_UNITS.volume.some(element => element === unit)) {
			unitSystem === "SI" ? newUnit = "l" : newUnit = "gal"
		}
		const converted = (unit === "hp" || unit === "kW") ? convertPower() : convert(value).from(unit).to(newUnit)
		// console.log("converted", converted)
		const digitized = Number(converted.toFixed(displayDigits))
		// console.log("digitized", digitized, displayDigits)
		const localized = Number.isNaN(digitized) ? "" : localize ? digitized.toLocaleString() : digitized
		// console.log("localized", localized)
		// console.log(`${localized}${!includeUnit ? "" : newUnit === "l" ? " L" : " " + newUnit}`)
		return `${localized}${!includeUnit ? "" : newUnit === "l" ? " L" : " " + newUnit}`
	} catch (e) {
		console.log(e)
		return "#"
	}
}

export const submitVesselTransport = event => {
	try {
		event.preventDefault()
		const levelOne = store.getState().window.current.levelOne
		store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window:
				PAGE_STATES[levelOne].MAIN.REQUESTS.REQUEST_SERVICE.VESSEL_TRANSPORT}})
	} catch (e) {
		console.log(e)
	}
}

export const submitEquipmentRental = event => {
	try {
		event.preventDefault()
		const levelOne = store.getState().window.current.levelOne
		const levelSix = store.getState().window.current.levelSix
		if (levelSix === PAGES.COMMON.MAIN.REQUESTS.MAKE_RESERVATION.EQUIPMENT_RENTAL.SUPPLIERS.X) {
			store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window:
					PAGE_STATES[levelOne].MAIN.REQUESTS.MAKE_RESERVATION.EQUIPMENT_RENTAL.PAYMENT.X}})
		} else {
			store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window:
					PAGE_STATES[levelOne].MAIN.REQUESTS.MAKE_RESERVATION.EQUIPMENT_RENTAL.SUPPLIERS.X}})
		}
	} catch (e) {
		console.log(e)
	}
}

export const submitVesselFueling = event => {
	try {
		event.preventDefault()
		const levelOne = store.getState().window.current.levelOne
		const levelSix = store.getState().window.current.levelSix
		if (levelSix === PAGES.COMMON.MAIN.REQUESTS.REQUEST_SERVICE.VESSEL_FUELING.TANKS.X) {
			store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window:
					PAGE_STATES[levelOne].MAIN.REQUESTS.REQUEST_SERVICE.VESSEL_FUELING.PAYMENT.X}})
		} else {
			store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window:
					PAGE_STATES[levelOne].MAIN.REQUESTS.REQUEST_SERVICE.VESSEL_FUELING.TANKS.X}})
		}
	} catch (e) {
		console.log(e)
	}
}

export const submitShuttleService = event => {
	try {
		const levelOne = store.getState().window.current.levelOne
		event.preventDefault()
		store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window:
				PAGE_STATES[levelOne].MAIN.REQUESTS.REQUEST_SERVICE.SHUTTLE_SERVICE.PAYMENT.X}})
	} catch (e) {
		console.log(e)
	}
}

export const getMarinaIndex = () => {
	try {
		const state = getFromStorage("state")
		return state.marina.index !== null ? state.marina.index : -1
	} catch (e) {
		console.log(e)
		return -1
	}
}

export const getVesselIndex = () => {
	try {
		const state = getFromStorage("state")
		return state.vessel.index !== null ? state.vessel.index : -1
	} catch (e) {
		console.log(e)
		return -1
	}
}

export const getVesselOwnership = () => {
	try {
		const state = getFromStorage("state")
		return state.vessel.ownership || "owned"
	} catch (e) {
		console.log(e)
		return "owned"
	}
}

export const getMarinaId = () => {
	try {
		const state = getFromStorage("state")
		return state.marina.id
	} catch (e) {
		console.log(e)
		return -1
	}
}

export const getVesselId = () => {
	try {
		const state = getFromStorage("state")
		return state.vessel.id
	} catch (e) {
		console.log(e)
		return -1
	}
}

export const setMarinaIndex = (id, marinas) => {
	try {
		const index = marinas.findIndex(marina => marina.organization.id === id)
		const state = getFromStorage("state")
		state.marina.index = index === -1 ? 0 : index
		setToStorage("state", state)
		return index
	} catch (e) {
		console.log(e)
		return -1
	}
}

export const setVesselIndex = (id, vessels, ownership = "owned") => {
	try {
		const index = vessels[ownership].findIndex(vessel => vessel.id === id)
		const state = getFromStorage("state")
		state.vessel.index = index === -1 ? 0 : index
		state.vessel.ownership = ownership
		setToStorage("state", state)
		return index
	} catch (e) {
		console.log(e)
		return -1
	}
}

/**
 *
 * @param {string} key
 * @param {string} [type = "local"] - "local" or "session" for localStorage or sessionStorage respectively
 * @returns {boolean|any} - returns the data if it is there, an empty object if it is empty, and false if the call fails
 */
export const getFromStorage = (key, type = "local") => {
	try {
		const localData = type === "local" ? localStorage.getItem(`${key}`) : sessionStorage.getItem(`${key}`)
		// console.log(localData)
		let storedData
		if (typeof localData !== "undefined" && localData !== null && localData !== "undefined") {
			try {
				const jsonParsed = JSON.parse(localData)
				storedData = jsonParsed ? jsonParsed : localData
			} catch (e) {
				storedData = localData
			}
		} else {
			storedData = false // {}
		}
		return storedData
	} catch (e) {
		console.log(e)
		return false
	}
}

export const setToStorage = (key, data, type = "local") => {
	try {
		const dataString = JSON.stringify(data)
		type === "local" ? localStorage.setItem(`${key}`, dataString) : sessionStorage.setItem(`${key}`, dataString)
	} catch (e) {
		console.log(e)
		return false
	}
}

export const replaceInStorage = (key, target, data, type = "local") => {
	try {
		const item = getFromStorage(key, type)
		item[target] = data
		setToStorage(key, item, type)
	} catch (e) {
		console.log(e)
		return false
	}
}

export const getLanguage = account => {
	try {
		const inStorage = store.getState().language
		return account ? account.preferences.language : inStorage || "en"
	} catch (e) {
		// console.log(e)
		return "en"
	}
}

export const getUnits = account => {
	try {
		const inStorage = store.getState().units
		return account ? (account.preferences.units || UNIT_SYSTEM.SI) : inStorage || UNIT_SYSTEM.SI
	} catch (e) {
		// console.log(e)
		return UNIT_SYSTEM.SI
	}
}

export const getTimeFormat = account => {
	try {
		const inStorage = store.getState().time
		return account ? account.preferences.use24Hr ? "24Hr" : "xm" : inStorage || "xm"
	} catch (e) {
		// console.log(e)
		return "xm"
	}
}

export const setLanguage = (language, sendToServer = true, refreshAccount) => {
	if (sendToServer) {
		setPreferredLanguage(language).then(() => refreshAccount().catch()).catch()
	}
	store.dispatch({type: actions.SET_LANGUAGE, values: {language: language}})
}

export const setUnits = (units, sendToServer = true, refreshAccount) => {
	if (sendToServer) {
		setPreferredUnits(units).then(() => refreshAccount().catch()).catch()
	}
	store.dispatch({type: actions.SET_UNITS, values: {units: units}})
}

export const setTimeFormat = (time, sendToServer = true, refreshAccount) => {
	if (sendToServer) {
		setPreferredTimeFormat(time).then(() => refreshAccount().catch()).catch()
	}
	store.dispatch({type: actions.SET_TIME, values: {time: time}})
}

export const translate = (dictionaryEntry, language = null) => {
	try {
		const lang = language ? language : getLanguage()
		return translate(DICTIONARY[dictionaryEntry][lang])
	} catch (e) {
		return dictionaryEntry
	}
}

export const arrayRange = (start, stop, step) =>
	Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export const ccBrandToLabelAndIcon = brand => {
	let icon = ICONS.CREDIT_CARD, label = translate(DICTIONARY.UNKNOWN_BRAND.X)
	switch (brand) {
		case "amex":
			icon = ICONS.CC_AMEX
			label = "American Express"
			break
		case "mastercard":
			icon = ICONS.CC_MASTERCARD
			label = "Mastercard"
			break
		case "visa":
			icon = ICONS.CC_VISA
			label = "Visa"
			break
		case "discover":
			icon = ICONS.CC_DISCOVER
			label = "Discover"
			break
		case "diners":
			icon = ICONS.CC_DINERS_CLUB
			label = "Diners Club"
			break
		case "jcb":
			icon = ICONS.CC_JCB
			label = "JCB"
			break
		case "unionpay":
			icon = ICONS.CREDIT_CARD
			label = "UnionPay"
			break
		default:
			break
	}
	return {label: label, icon: icon}
}

export const checkIfPushEnabled = async () => {
	try {
		if (!("Notification" in window)) {return false}
		// console.log("checkIfPushEnabled called")
		const messaging = getMessaging()
		// console.log("messaging", messaging)
		try {
			let permission = Notification.permission
			if (permission === "default") {
				permission = await Notification.requestPermission()
			}
			// console.log("push permission provided", permission)
			try {
				if (permission === "granted") {
					const token = await getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_PUBLIC_KEY})
					const token_ = getFromStorage("pushToken", "session")
					// console.log("push token provided", token)
					if (token !== token_ && getLoggedIn()) {
						const response = await setPushSubscription(token)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							// console.log("subscribed to push - server")
							setToStorage("pushToken", token, "session")
							return true
						} else {
							return false
						}
					} else {
						// console.log("no push token available")
						return false
					}
				} else {
					return false
				}
			} catch (e) {
				console.log("error fetching push token", e)
				return false
			}
		} catch (e) {
			console.log("push permission denied", e)
			return false
		}
	} catch (e) {
		console.log(e)
		return false
	}
}

export const parsedLengthToMeters = (whole, fraction, units) => {
	try {
		return units === "SI" ? Number(`${whole}.${fraction}`) :
			Number(convertToPreferredUnits(whole + fraction/12, "ft", "SI", 6,
				false, false))
	} catch (e) {
		console.log(e)
		return ""
	}
}

/**
 *
 * @param {string} path
 * @param {[{key: string, value: string}]} data
 * @param {string|null} [id = null]
 */
export const appendScript = (path, data, id = null) => {
	try {
		if (id) {
			try {
				const element = document.getElementById(id)
				if (element) {
					return
				}
			} catch (e) {
				console.log(e)
			}
		}
		const script = document.createElement("script")
		if (id) {script.id = id}
		script.src = path
		script.async = true
		for (const each in data) {
			script.dataset[`${each}`] = `${data[each]}`
		}
		document.head.appendChild(script)
		return script
	} catch (e) {
		console.log(e)
	}
}

export const getSelectedVesselAndMarina = () => {
	try {
		const state_ = getFromStorage("state")
		return Object.keys(state_).length === 0 ? {vessel: {index: 0}, marina: {index: 0}} : state_
	} catch (e) {
		console.log(e)
		return {vessel: {index: 0}, marina: {index: 0}}
	}
}

/**
 *
 * @param {number} milliseconds
 * @return {null|number}
 */
export const msToMins = milliseconds => {
	try {
		return Math.ceil(milliseconds/60000)
	} catch (e) {
		console.log(e)
		return null
	}
}
/**
 *
 * @param {number} milliseconds
 * @return {null|number}
 */
export const msToHrs = milliseconds => {
	try {
		return Math.round(milliseconds/3.6e6)
	} catch (e) {
		console.log(e)
		return null
	}
}
/**
 *
 * @param {number} milliseconds
 * @return {{number: (number|null), type: (string)}|{number: null, type: null}}
 */
export const msToHrsOrMins = milliseconds => {
	try {
		const hrs = milliseconds/3.6e6
		const mins = msToMins(milliseconds)
		return {number: hrs < 1 ? mins : Math.round(hrs), type: hrs < 1 ? "MINUTES" : "HOURS"}
	} catch (e) {
		console.log(e)
		return {number: null, type: null}
	}
}
/**
 *
 * @param {Error} e
 * @param {string} component
 * @param {"error"|"warning"} level
 */
export const manageCrash = (e, component, level = "error") => {
	newMessage("crash-message", translate(DICTIONARY.ERROR.X), translate(DICTIONARY.CRASH_MESSAGE.X))
	if (process.env.REACT_APP_PUBLIC_URL === "https://paralian.app") {
		logError(e, component).then().catch()
		if (level === "error") {
			setTimeout(() => window.location.reload(), 5000)
		}
	} else {
		console.log("------------START--------------")
		console.log(level, component || "COMPONENT", e)
		console.log("------------END----------------")
	}
}
