import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {adjustOrderPrice} from "../../../../../globals/functions/client/serverFunctions"
import MobileOrderAdjustPriceCard from "../../MobileOrderAdjustPriceCard"
import MobileInputText from "../../../../shared/input/text/MobileInputText"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
import Modal from "../../../../../globals/components/Modal"
import MobileInputSlider from "../../../../shared/input/switch/MobileInputSlider"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.charges
 * @param {{order: number, item: number, isAdjustment: boolean, quantity: number, charge: number}[]} props.order.charges.items
 * @param {{items: number, taxes: number, total: number}} props.order.charges.total
 * @param {{available: number, captured: number, credits: number, onHold: number, refunded: number, receipts: string[]}} props.order.refunds
 * @param {IndexedArray} props.services
 * @param {Currency} props.currency
 * @param {boolean} props.showProposeAdjustPriceModal
 * @param {function} props.setShowProposeAdjustPriceModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const ProposeAdjustOrderPriceModal = props => {
	const [fixPrices, setFixPrices] = useState(false)
	const order = props.order
	return props.showProposeAdjustPriceModal ? <Modal id={`order-${order.id}-adjust-modal`} icon={ICONS.COINS}
		onClick={() => props.setShowProposeAdjustPriceModal(false)} title={translate(DICTIONARY.PROPOSE_PRICE_CHANGE.X)}
		body={<>
			{
				order.charges.items.map(item => <MobileOrderAdjustPriceCard key={`order-${order.id}-details-${item.item}`}
																																		order={order} item={item}
																																		indexedServices={props.services}/>)
			}
			<div className={"paralian-theme-level-1"} style={{padding: "2vh", margin: "1vh 0vh"}}>
				<MobileInputText id={`order-${order.id}-adjust-reason`} label={translate(DICTIONARY.EXPLANATION.X)}
												 pattern={"([a-zA-ZÀ-ž0-9-_\.]){2,128}"} showLabel={true} className={"paralian-theme-level-2"}
												 required={true}
												 placeholder={translate(DICTIONARY.EXPLANATION.X)}/>
			</div>
			<div style={{width: "100%", height: "5vh", margin: "1vh 0vh", padding: "1vh 0vh"}}
					 className={"w3-display-container paralian-theme-level-1"}>
				<MobileInputSlider id={`order-${order.id}-lock-price`} label={translate(DICTIONARY.FIX_PRICES.X)} value={fixPrices}
					padding={"0.25vh 0vh 0.25vh 2vh"} height={"3vh"} width={"8vh"}
					className={"w3-display-middle"} defaultChecked={fixPrices} onChange={() => {setFixPrices(!fixPrices)}}/>
			</div>
			<MobileButtonGeneric id={"submit-propose-adjust"} type={"button"} className={"paralian-theme-level-1-flipped"}
													 padding={"1vh"} style={{width: "100%"}} text={{content: translate(DICTIONARY.SUBMIT.X)}}
													 onClick={async () => {
														 let confirmed = false
														 console.log("order.recurrence.id", order.recurrence.id)
														 if (fixPrices && order.recurrence.id !== null) {
															 confirmed = window.confirm(translate(DICTIONARY.FIX_PRICES_CONFIRM.X))
														 }
														 try {
															 const items = order.charges.items.map(item => {
																 return {
																	 item: item.item,
																	 quantity: Number(document.getElementById(`order-${order.id}-item-${item.item}-adjust-quantity`).value),
																	 unitPrice: props.currency.asZeroDecimal(document.getElementById(`order-${order.id}-item-${item.item}-adjust-unit-price`).value)
																 }
															 })
															 const message = document.getElementById(`order-${order.id}-adjust-reason-input`).value
															 console.log("order.id", order.id, "items", items, "message", message, "fixPrices", fixPrices, "confirmed", confirmed)
															 const response = await adjustOrderPrice(order.id, items, message, fixPrices, confirmed)
															 // console.log(response)
															 if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
																 props.refreshOrders()
																 props.setShowProposeAdjustPriceModal(false)
																 fadingMessage("propose-adjust-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
															 }
														 } catch (e) {
															 console.log(e)
														 }
													 }}/>
		</>}/> : <></>
}

export default ProposeAdjustOrderPriceModal